@import "../../init";
@include media-breakpoint-down(xs) {
    .dashboard-main-content{
        .card{
            .card-body{
                .row{
                    flex-direction: column;
                    .bg-light-warning{
                        margin-right: 0!important;
                        margin-bottom: 3rem!important;  
                    }
                }
            }
        }
    }
 }
 .color-red{
     color: red!important;
 }
.ios-zindex{
    z-index: 99 !important;
}
 .border-top-title{
    border-top: 1px solid #d2d2d2;
 }

 .content{
    .btn-primary, .blue-button,.join-button, .see_more_btn {
        background-position: center;
        transition: background 1s;
      }
      .btn-primary:hover , .blue-button:hover,.join-button:hover, .see_more_btn:hover {
        background: $btn-primary radial-gradient(circle, transparent 1%, $btn-primary 1%) center/15000%;
      }
      .btn-primary:active, .blue-button:active,.join-button:active, .see_more_btn:active  {
        background-color:$btn-primary;
        opacity: .7;
        background-size: 100%;
        transition: background 1s;
      
      }
      .btn-danger {
        background-position: center;
        transition: background 1s;
      }
      .btn-danger:hover {
        background: $btn-primary radial-gradient(circle, transparent 1%, #ff5858 1%) center/15000%;
      }
      .btn-danger:active {
        background-color:#ff5858;
        opacity: .7;
        background-size: 100%;
        transition: background 1s;
     
      }
 }
 
 @keyframes effect_dylan {
    50% {
      transform: scale(1.5, 1.5);
      opacity: 0;
    }
    99% {
      transform: scale(0.001, 0.001);
      opacity: 0;
    }
    100% {
      transform: scale(0.001, 0.001);
      opacity: 1;
    }
  }
 .height-100{
    height: 100%;
}
.unwanted{
    display: none!important;
}
.topbar-info{
    .tooltip-show{
        .myaccount{
            display: none;
            left: 40px;
            text-align: center;
        }
    }
}
.tooltip-show{
    position: relative;
    .Custom-tooltip{
        font-size: 11px;
        color: #3f4254;
        position: absolute;
        display: none;	
        text-align: center;
        background-color: #fff;
        border-radius: 4px;
        top: 34px;
        left: -10px;
        width: 120px;
        padding: 6px 5px;
        box-shadow: 3px 4px 22px -1px rgba(0,0,0,0.26);
        -webkit-box-shadow: 3px 4px 22px -1px rgba(0,0,0,0.26);
        -moz-box-shadow: 3px 4px 22px -1px rgba(0,0,0,0.26);
        z-index: 99;
        
    }
    .minimize-tool{
        right: 40px;
        top: 1px;
        width: auto;
        left: inherit;
    }
    .see-map{
        width: 90px;
        left: 30%;
        top: 24px;
    }
    &:hover .Custom-tooltip{
        display: block;
    }
}
#kt_quick_search_toggle{
    .tooltip-show{
        .Custom-tooltip{
            top: 50px;
            width: 100%;
        }
    }
}
 #pdf-pagination{
    gap: 20px;
    margin-right: 12px;
 }
 .hide-tooltip{
     display: none;
 }
 .w-80{
    width: 80px;
}
.disable-field{
    filter: grayscale(104%);
    opacity: .6;
}
 .activate-btn{
     background-color: $btn-primary;
     padding: 6px!important;
     width: 130px;
     border: none;
     border-radius: 4px;
     color: #fff;
     cursor: pointer;
     .spinner-white{
         width: 8px;
     }
     &:hover{
         background-color: #187DE4;
     }
 }
 .deactivate-btn{
     @extend .activate-btn;
     background-color: #aaaaaa;
     &:hover{
        background-color: #9c9c9c;
    }
 }
 .back-btn-wrap{
     .back-btn{
        border: 1px solid $btn-primary!important;
        color: $btn-primary;
        padding: 8px 10px;
        border: none;
        border-radius: 4px;
        width: 100px;
        svg{
            width: 19px;
            margin-right: 6px;
            fill: $btn-primary;
        }
        &:hover{
            background-color: $btn-primary;
            color: #fff;
            svg{
                fill: #fff;
            }
        }
       
     }
 }
 .card-toolbar{
     .study-name{
         font-size:15px;
         font-weight: bold;
         margin-bottom: 10px;
     }
     .study-label{
         font-weight: bold;
     }
     .card-button-wrap{
         border-top: 1px solid rgb(211, 211, 211);
         padding-top:25px;
         margin-top: 25px;
     }
 }
// Account css here
.my-account-wrapper{
    .ph-0{
        padding-right: 0!important;
    }
    form .form-group{
        margin-bottom: 30px!important;
    }
    .card-label{
        font-size: 22px!important;
        font-weight: normal!important;
    }
    .sub-heading{
        font-size: 16px;
        margin-top: 8px!important;
    }
    .image-input-wrapper{
        border-radius: 100px!important;
    }
    .image-input [data-action=change] {
        position: absolute;
        right: 16px;
        bottom: -9px;
        top: auto;
    }
    .btn.btn-icon.btn-xs {
        height: 32px;
        width: 32px;
        box-shadow: none!important;
        background-color: #FF8029;
        border-color:#FF8029 ;
        &:hover{
            background-color: #d15706!important;
            box-shadow: none!important;
            border-color:#d15706!important ;

        }
    }
    .MuiTextField-root{
        width: 100%;
    }
    .country-code{
        width: 80px;
    }
    .country-name,.state,.city{
        width: 33%;
    }
    .number-wrap{
        flex: 1;
    }
    .change-pass-btn{
        width: 152px;
        height: 33px;
        display: block;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        border-color: $btn-primary;
        padding: 10px;
        font-size: 14px;
        line-height: 13px;
        color:$btn-primary;
        border-color: $btn-primary!important;

        &:hover{
            background-color: $btn-primary!important;
        }
    }
    .save-btn{
        @extend .change-pass-btn;
        background-color: $btn-primary!important;
        color: #fff;
        border-color: $btn-primary!important;
        width:116px;
    }
   
    .left-side{
        text-align: center;
    }

    .MuiInput-underline:before{
        border-bottom:1px solid #CECECE !important;
    }
    .f-15{
        .MuiInput-input{
            font-size: 15px!important;
        }
    }
    .address{
        margin-top: -10px;
    }
    .image-input.image-input-outline .image-input-wrapper{
        box-shadow: none!important;
        border: 0px solid transparent!important;
    }
    .form-group label{
        font-size: 14px;
    }
}
 .log-out-btn{
    display: flex;
    width:116px;
    align-items: center;
    justify-content: center;
    border-color: $btn-primary !important;
    background-color: $btn-primary!important;
    padding: 10px;
    font-size: 14px;
 }
 .uplaod-profile{
    .btn-wrap{
     border-top: 1px solid #dfdfdf;
     justify-content: center;

    }
    .MuiDialog-paperWidthSm{
        border-radius: 10px;
    }
    .save-btn{
        align-items: center;
        justify-content: center;
        background-color: $btn-primary!important;
        border-color:$btn-primary!important;
        font-size: 14px;
        opacity: 1!important;
    }
    .middle-content{
        border: 2px dashed #b8b7b7;
        margin: 20px;
        text-align: center;
        border-radius: 10px;
        padding: 60px;
    }
 }
//  File Upload

ul.menu-nav .menu-item-active .svg-icon svg g [fill]{
    fill: $link-color!important;
}
ul.menu-nav .menu-item-active span{
    color:$link-color!important;
}

.cancel-btn{
    border:1px solid #FC4B42;
    color: #FC4B42;
    height: 33px;
    width:103px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.close-btn{
    border:1px solid #FC4B42;
    color: #fff!important;
    height: 33px;
    width:88px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color:#FC4B42 !important;
    border-radius: 4px;
}
.session-text{
    font-size: 16px;
    display: inline;
}
//upload button css
.uplaod-button-wrap{
    .middle-content{
        padding: 20px!important;
    }
    
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  .button-wrap {
    position: relative;
    z-index: 2;
    .new-button {
        display: inline-block;
        padding: 8px 12px; 
        cursor: pointer;
        border-radius: 4px;
        background-color: #F7F7F7;
        font-size: 14px;
        color: #494E56;
        border: 1px solid #DFDFDF;
    }
    input[type="file"] {
        position: absolute;
        z-index: -1;
        top: 6px;
        left: 48px;
        font-size: 15px;
        color: #494E56;
        font-size: 13px;
      }
  }

}
.text-color{
    color: #494E56;
    font-size: 14px;
}
.add-btn{
    background-color: $btn-primary!important;
    border-color: $btn-primary!important;
}
.addsymbol{
    font-size: 15px;
    font-weight: bold;
    margin-right: 6px;
}
.uplaod-profile {
    .MuiDialog-scrollPaper{
        .uplaod-button-wrap{
            padding: 0!important;
        }

        .MuiDialog-paperWidthSm {
            .upload-profile-img{
                padding: 20px!important;
                margin: 0!important;
                width: 100%!important;
            }
        }
    }
    
}
.room{
    background-color: #212121;
    .user-wrap-height, .drawer-height{
        height: calc(100vh - 95px)!important;
    }
}
.ctms-video-conf {
    .room{
        margin-top: 0;
    }
}
.drawer-height{
    .MuiDrawer-paper{
        height: calc(100vh - 95px)!important;
        top: 65px;
    }
   
}
.header-fixed.subheader-fixed.subheader-enabled .header-pt{
    padding-top: 0!important;

}
.log-out-wrap{
    button,a{
        height: 40px;
        width: 75px;
    }
 }
@include media-breakpoint-down(sm) {
    .middle-content{
        width: 492px;
        overflow-y: visible!important;
        margin: 10px;
    }
    .aside-minimize .footer{
        left: 0!important;
    }
    
    
}
.popup-heading{
    padding: 6px 8px!important;
    border-bottom: 1px solid #dfdfdf;
    background-color: $dark-blue;
    color: #fff;
   h6{
    letter-spacing: .5px;
    padding:5px 10px;
    border-radius: 10px;
    font-size: 15px;
    text-align: center;
   }
}
.block-popup{
    .ios-text{
        text-align: left;
    }
    .step-text {
        font-size: 15px;
    }
}
//image Progress bar
.progress_bar_2 {
    display: inline-block;
    position: relative;
    width: 200px;
    height: 6px;
    border-radius: 10px;
    background: linear-gradient(to right, $btn-primary 50%, #dadada 50%);;
    background-size: 400px 20px;
    animation: progress_bar_2 6s linear infinite;
    align-self: flex-start;
}
@keyframes progress_bar_2 {
    0% {
        background-position: 100% 0;
    }
    25% {
        background-position: 75% 0;
    }
    50% {
        background-position: 50% 0;
    }
    75% {
        background-position: 25% 0;
    }
    100% {
        background-position: 0 0;
    }
}
.image-loader-popup{
    .MuiDialog-paper{
        background: transparent;
        box-shadow: none;
        .MuiDialogContent-dividers{
            border: none;
        }
    }
}
// Success popup
.success-popup{   
    z-index: 9999!important;  
    .success-icon{
        display: flex;
        align-items: center;
        justify-content: center;
        .icon{
            color: $success;
            font-size: 40px;
            border-radius: 100px;
            border: 4px solid $success-light;
            width: 80px;
            padding: 35px;
            @extend .success-icon
            
         }
    }   
    .save-btn{
        width: 80px;
    }
    .success-text{
        font-size: 14px;
    }
    .MuiDialog-paperWidthSm{
        border-radius: 10px;
        width: 30%;
    }
    .success-head{
        font-weight: bold!important;
    }
}
// Log out popup
.log-out-wrap{
    .MuiDialog-paperWidthSm{
        width:30%;
        border-radius: 10px;
    }
    .middle-content{
        padding: 40px 16px;
        .logut-content{
            font-size: 14px;
        }
    }
    .logout-btn{
        background-color: $btn-primary;
        color: #fff;
    }
    .btn-wrapper{
        width: 100%;
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 24px 10px;
    }
}

// Input color
.MuiFormLabel-root.Mui-focused{
    color: #E7C235!important;
}
.MuiInput-underline:after{
    border-bottom: 2px solid #E7C235!important;
}

.MuiInput-underline:focus:not(.Mui-focused):before,
 .MuiInput-underline:active:not(.Mui-focused):before, 
 .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 1px solid #E7C235 !important;
}
.MuiInputBase-inputMultiline{
    font-size: 14px!important;
}
.card-spacer button {
    background: #0070C0;
    color: #fff;
    font-size: 13px;
    border-radius: 8px;
    border: 1px solid #0070C0;
    padding: 10px 15px;
}
.MuiDialog-paperWidthSm {
    max-width: 553px;
    width: 553px !important;
}
.MuiDialogContent-root {
    padding: 25px !important;
    .welcome-heading h4 {
        text-align: center;
        letter-spacing: 0.44px;
        color: #E1C42B;
        font-size: 26px;
        font-weight: 500;
        margin-bottom: 8px;
    }
    .welcome-heading span {
        color: #34434E;
        font-size: 16px;
        font-weight: 500;
        display: block;
        margin-bottom: 30px;
    }
    .success-img {
        margin-bottom: 30px;
        text-align: center;
        width: 100%;
        float: left;
        position: relative;
        img{
            max-width: 100%;
        }
        .left-arr {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        .right-arr {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
        }
        
    }
    .wel-listing {
        width: 100%;
        float: left;
        .wel-listing-inner {
            width: 100%;
            float: left;
            margin-bottom: 15px;
            border-radius: 8px;
            border: 1px solid #E0E6EA;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
            padding: 10px;
            background: #F7F7F7;
            img {
                float: left;
                margin-right: 15px;
            }
            span {
                float: left;
                line-height: 30px;
                font-size: 13px;
                color: #000;
            }
        }
        .wel-listing-inner:nth-child(2n) {
            background: #fff;
        }
    }
    .MuiDialogContent-root button {
        margin: 0 !important;
    }
}
.MuiBackdrop-root {
    -webkit-backdrop-filter: blur(5px);
    backdrop-filter: blur(5px);
    background-color: rgb(0 0 0 / 50%);
}
.MuiDialogContent-root .pagination {
    width: 100%;
    float: left;
    text-align: center;
    display: block;
    ul {
        padding: 0;
        margin: 25px 0 0 0;
        text-align: center;
        list-style-type: none;
    }
    li {
        width: 9px;
        height: 9px;
        background: #E1C42B;
        display: inline-block;
        border-radius: 50%;
        opacity: 0.6;
        margin-right: 10px;
    }
    li.active {
        width: 50px;
        border-radius: 8px;
        opacity: 1;
    }
}
.welcome-heading {
    width: 100%;
    float: left;
    position: relative;
    .close-icon {
        position: absolute;
        right: 0;
        top: 5px;
    }
}
.dashboard-main-content {
    width: 100%;
float: left;
}
.dash-top{
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    border: 0;
    background: #fff !important;
    margin-bottom: 15px;
    padding: 15px;
    width: 100%;
    border-radius: 8px;
    float: left;
    border: 1.5px solid $link-color;
    .dash-top-left {
        float: left;
        width: 65%;
        img {
            float: left;
            max-height: 40px;
            margin-right: 10px;
            margin-bottom: 0 !important;
            max-width:70px;
            min-width: 70px;
        }
        span {
            display: block;
            text-align: left;
            font-size: 15px;
            font-weight: 700;
        }
        h3{
            font-size: 20px;
        }
        p{
            margin: 0;
            font-size: 14px;
            text-align: left;
            font-weight: 100;
            text-transform: none;
            margin-left: 80px;
        }
        a{
            color: $link-color
        }
    }
    .dash-top-right {
        width: 35%;
        float: right;
        text-align: right;
        margin-top: 8px;
    }
}
button.blue-button {
    background: $btn-primary;
    border: 1px solid $btn-primary;
    border-radius: 8px;
    font-size: 13px;
    color: #fff;
    margin-right: 15px;
    padding: 6px 28px;
}
.no-btn{
    width: 80px;
}
button.red-button {
    background: #ffffff;
    border: 1px solid #FF8029;
    border-radius: 8px;
    font-size: 13px;
    color: #FF8029;
    padding: 6px 14px;
}
.dash-event{
    width: 100%;
    float: left;
 .dash-event-inner {
    width: 49%;
    float: left;
    margin-right: 2%;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    border: 0;
    background: #fff !important;
    margin-bottom: 15px;
    display: flex;
    align-items: flex-end!important;
    justify-content: center;
    .event-info {
        float: left;
        width: 80%;
        .amount-text{
            font-size: 20px;
            display: inline-block;
        }
        .error-msg{
            font-size: 14px;
            margin-bottom: 10px;
        }
    }
    .dash-event-inner.active {
        border: 1px solid #E1C42B;
    }
    .event-info h2 {
        font-size: 26px;
        color: #34434E;
        font-weight: 900;
    }
    .event-info p{
        margin: 0;
        color: $link-color;
        font-size: 14px;
    }
    .event-img {
        float: left;
        width: 20%;
        text-align: right;
    } 
       
    }
    .dash-event-inner:last-child {
        margin-right: 0;
    }
  
}
.text_style{
    font-style: italic;
}
.total-bal-text{
    color: #e1c42b;
}
.dash-event-inner.active .event-info h2, 
.dash-event-inner.active .event-info p {
    color: $link-color !important;
}
.dash-noti{
    width: 100%;
    float: left;
    margin-right: 0;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    border: 0;
    background: #fff !important;
    margin-bottom: 45px;
    height: calc(100vh - 265px);
    overflow-y: auto;
}
.dash-reminder{
    width: 32%;
    border-radius: 8px;
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    border: 0;
    background: #fff !important;
    float: left;
    height: calc(100vh - 140px);
    overflow-y: hidden;
    display: grid;
    grid-template-rows: 50% 50%;
    .todays-plan-wrapper{
        .spinner{
            margin: 10px;
        }
        
        .spinner:before{
            left: -6px;
        }
    }
    .reminder-alert{
        .spinner{
            margin: 10px;
        }
        .spinner:before{
            left: -6px;
        }
    }
    
    .join-button{
        padding: 4px 13px;
        margin: 0;
        background: $btn-primary;
        border: 1px solid $btn-primary;
        font-size: 13px;
        border-radius: 8px;
        color: #fff;
        font-size: 14px!important;
    }
}
.upcomining-inner{
    height: calc(100% - 83px);
    clear: both;
    overflow: auto;
    .inner-wrap{
        padding:0 15px;
        h6{
            font-family: 'Poppins';
        }
    }
}

.reminder-heading {
    padding-bottom: 15px;
    width: 100%;
    border-top: 2px solid #E0E6EA;
    float: left;
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 13px;
    img{
        margin-right: 5px;
        float: left;
    }
    span{
        font-size: 16px;
    color: #34434E;
    display: block;
    float: left;
    font-weight: 600;
    }
}
.reminder-alert {
    width: 100%;
    float: left;
    background: #F7FAFF;
    border: 1px solid #ececec;
    border-radius: 16px;
    margin-bottom: 15px;
    padding: 8px 8px;
    position: relative;
    cursor: pointer;
    .time-date-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        clear: both;
        .btn-join{
            padding: 3px 14px;
            margin-right: 0;
        }
    }
    .reminder-img {
        float: left;
        margin-right: 8px;
        width: 44px;
    }
    .reminder-text-wrap{
        width: calc(100% - 53px);
        float: left;
    }
    .upcoming-info{
        display: flex;
        .left-section{
            flex: 7;
            .location{
                margin-bottom: 0;
            }
        }
        .right-section{
            display: flex;
            flex-direction: column;
            .resize-icon{
                align-self: flex-end;
                margin-bottom: 20px;
            }
        }
    }
    .reminder-text {
        width:100%;
        float: left;
    }
    p{
        font-size: 12px;
        color: #34434E;
        margin-bottom: 5px;
        float: left;
    }
    span {
        font-size: 11px;
        color: #8E8E8E;
    }
    h6{
        font-size: 20px;
        color: #34434E;
        font-weight: 600;
    }
    .right-arrr {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        h6{
            font-size: 13px;
            color: #34434E;
            font-weight: 500;
            word-break: break-all;
            margin-bottom: 0;
        }
       
    }
}
.resize-icon{
    width: 18px;
    cursor: pointer;
    margin-left: 20px;
}

.dash-noti-heading {
    width: 100%;
    float: left;
    border-bottom: 1px solid #E0E6EA;
    padding-bottom: 10px;
    h5{
        color: #34434E;
        font-size: 16px;
        float: left;
        font-weight: 500;
        margin: 8px 0;
    }
    .dash-noti-btns {
        float: right;
        button{
            margin: 0;
        }
    }
}
.notification_time{
    position: absolute;
    top: 20px;
    font-size: 13px;
}
.dash-noti-content {
    width: 100%;
    float: left;
    margin-top: 15px;
    .noti-content-inner {
        width: 100%;
        float: left;
        margin-bottom: 15px;
        position: relative;
        .content-date {
            float: left;
            width: 130px;
            position: relative;
            p{
                color: #9E9E9E;
                font-size: 14px;
                font-weight: 100;
                margin: 28px 0;
            }
        }
        .content-detail {
            width: calc(100% - 130px);
            float: right;
            background: #F7F7F7;
            box-shadow: 0 2px 6px rgb(0 0 0 / 10%);
            border-radius: 8px;
            padding: 15px;
            position: relative;
            .content-detail-img {
                margin-left: 15px;
                float: left;
                max-width: 34px;
                width: 34px;
                margin-right: 10px;
            }
            .date-content-info {
                float: left;
                width: calc(100% - 140px);
                p{
                    font-size: 13px;
                    color: #000;
                    margin-bottom: 8px;
                    font-family: 'Poppins';
                }
                p:last-child{
                    margin: 0 !important;
                }
            }
            .content-time {
                float: right;
                font-size: 13px;
                color: #8E8E8E;
            }
            
        }
    }
}
#kt_scrolltops{
    position: fixed;
    bottom: 70px;
    right: 40px;
    cursor: pointer;
    .svg-icon{
        background-color: #22b2c4;
        padding: 8px;
        border-radius: 6px;
        opacity: .5;
        &:hover{
            opacity: 1;
        }
        path{
            fill: #fff;
        }
    }
}
.my-notifiation{
    .my-notification-inner{
        .dash-noti-content{
            .noti-content-inner{
                .content-detail{
                    width: calc(100% - 12%);
                }
            }
        }
    }
}
.event-title{
    font-size: 12px;
    margin-right: 10px;
    word-break: break-word;
}
.todays-plan-wrapper{
    overflow: hidden;
    padding-bottom: 10px;
    clear: both;
    .today-inner{
        height: 100%;
        overflow-y: auto;
    }
    .today-plan-heading{
        padding: 0px 0px 15px 15px;
        margin-top: 15px;

        span{
            font-size: 16px;
            color: #34434E;
            font-weight: 700;
            margin-left: 5px;
        }
    }
    .resize-wrap{
        flex: 1;
    }
    .today-plan-content-wrap{
        display:flex;
        position: relative;
        .mark-completed{
            padding: 6px 15px;
            font-size: 12px;
            margin-top: 5px;
            margin-right: 0;
        }
        .vertical-line{
            width: 20px;
            margin-right: 5px;
            .circle{
                width: 21px;
                height: 21px;
                border: 3px solid #E7C235;
                border-radius: 100%;
                position: absolute;
                left: -5px;
                background-color: #fff;
                top: 30px;
            }
            &::before{
                content: '';
                width: 2px;
                height: 95%;
                background-color: #DBDBDB;
                position: absolute;
                top: 40px;
                left: 4px;
        }
        .check-icon{
            width: 21px;
            height: 21px;
            position: absolute;
            top: 30px;
            left:-5px
        }
        }
        .meeting-content{
            display: flex;
            .left-section{
                flex: 7;
            }
        }
        .today-plan-content{
            flex: 10;
            width: 100%;
            background: #F7FAFF;
            border: 1px solid #ececec;
            border-radius: 16px;
            margin-bottom: 15px;
            padding: 8px 8px;
            .time{
                display: flex;
                justify-content: space-between;
                .time_left{
                    display: flex;
                    align-items: center;
                    width: 100%;
                    span{
                        font-size: 14px;
                        margin-left: 10px;
                        font-weight: 600;
                    }
                    .clock-icon{
                        width: 22px;
                    }
                }
              
            }
            .time_right{
                font-size: 10px;
                color: #1CB400;
                position: relative;
                margin-top: 6px;
                margin-left: 10px;
                cursor: text;
                &::before{
                    content: '';
                    width: 5px;
                    height: 5px;
                    background-color: #1CB400;
                    position: absolute;
                    border-radius: 100%;
                    top: 3px;
                    left: -10px;
                }
            }
            .title-wrap{
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-top: 5px;
                span{
                    color: #34434E;
                    font-size: 13px;
                    flex: 3;
                    cursor: text;
                }
                .blue-button{
                    margin-right: 0!important;
                    padding: 3px 18px;
                    flex: 1;
                }
                .btn-join{
                    padding: 3px 14px;
                }
                
            }
            .rout{
                font-size: 12px;
                cursor: text;
                color: #34434E;
            }
            .left-section{
                .location{
                    margin-bottom: 0;
                }
            }
           .right-section{
               display: flex;
               flex-direction: column;
               .resize-icon{
                   align-self: flex-end;
                   margin-bottom: 20px;
               }
           }
           .taken-dosages{
               margin: 0;
               font-size: 12px;
               color: #000;
               cursor: text;
           }
        }
       
    }
    .location{
        p{
            margin-bottom: 0;
        }
    }
   
}
.sticky {
    position: fixed;
    top: 0;
    width: 100%;
  }
  
  .sticky + .content {
    padding-top: 102px;
  }

//Quick Search
.global-search{
    .event-details{
        margin-bottom: 25px;
        box-shadow: 3px 13px 9px -12px rgba(0,0,0,0.23);
        -webkit-box-shadow: 3px 13px 9px -12px rgba(0,0,0,0.23);
        -moz-box-shadow: 3px 13px 9px -12px rgba(0,0,0,0.23);
        overflow: hidden;
        .study-Details{
            .left-side{
                display: flex;
                align-items: center;
                gap: 100px;
            }
        }
        .no-data-found{
            text-align: center;
            img{
                margin-bottom: 10px;
            }
            p{
                font-weight: bold;
            }
        }

    }
    .heading{
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 15px;
    }
    .quick-event{
        width: 100%;
        background: #F7FAFF;
        border: 1px solid #ececec;
        border-radius: 16px;
        margin-bottom: 15px;
        padding: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        &:last-child{
            margin-bottom: 0;
        }
 
        .details-icon{
            width: 20px;
            height: 20px;
            margin-left: 40px;
            cursor: pointer;
            }
            .join-button{
                padding: 5px 14px;
                display: flex;
                align-items: center;
                background: $btn-primary;
                border: 1px solid $btn-primary;
                font-size: 13px;
                border-radius: 8px;
                color: #fff;
            }
            p{
                margin: 0;
            }
            .study-Details{
                display: flex;
                align-items:center;
                width: 100%;
                .visit-icon{
                    width: 40px;
                }
                h4{
                    font-size: 14px;
                    font-weight: bold;
                }
                .left-side{
                    flex: 3;
                }
                .Right-side{
                    flex: 1;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    flex-wrap: wrap;
                }
                .status{
                    padding: 4px 8px;
                    border: 1px solid #1CB400;
                    border-radius: 10px;
                    font-size: 10px;
                    color: #1CB400;
                }
                .unpaid{
                    border: 1px solid #ff5858;
                    color: #ff5858;
                }
                .amount{
                    font-size: 18px;
                }
            }
           
        }
        // visit form
        .visit-form{
            margin-bottom:50px !important;
            .width{
                width: 100%;
            }
            .user-details{
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                width: 100%;
                .user-name{
                    flex: 40%;
                }
                .time{
                    flex: 30%;
                }
                p{
                    margin: 0;
                    color: #858585;
                    font-size: 12px;
                    font-weight: normal;
                }
                .status{
                    flex: 30%;
                    span{
                        color: #00d200
                    }
                }
            }
            
        }
        
        .my-stipend{
           margin-bottom:0 0 25px;
            .status-wrap{
                gap: 60px;
               
                
            }
        }
        .no-data-found-wrap{
            .no-data-found{
                display: flex;
                flex-direction: column;
                align-items: center;
                img{
                    margin-bottom: 10px;
                }
                p{
                    font-weight: bold;
                }
            }
        }
        .border-top{
            border-top: 1px solid #8E8E8E;
            padding-top: 15px;
        }
        .today-plan-content-wrap{
            display:flex;
            position: relative;
            .vertical-line{
                width: 20px;
                margin-right: 5px;
                .circle{
                    width: 21px;
                    height: 21px;
                    border: 3px solid #E7C235;
                    border-radius: 100%;
                    position: absolute;
                    left: -5px;
                    background-color: #fff;
                    top: 30px;
                }
                &::before{
                    content: '';
                    width: 2px;
                    height: 95%;
                    background-color: #DBDBDB;
                    position: absolute;
                    top: 40px;
                    left: 4px;
            }
            .check-icon{
                width: 21px;
                height: 21px;
                position: absolute;
                top: 30px;
                left:-5px
            }
            }
            .meeting-content{
                display: flex;
                .left-section{
                    flex: 7;
                }
            }
            .today-plan-content{
                flex: 10;
                width: 100%;
                background: #F7FAFF;
                border: 1px solid #ececec;
                border-radius: 16px;
                margin-bottom: 15px;
                padding: 8px 8px;
                cursor: pointer;
                .medication-head{
                    cursor: text;
                }
                .date-wrap{
                    margin-top: 5px;
                    .date{
                        margin-right: 35px;
                        font-size: 12px;
                    }
                }
                .time{
                    display: flex;
                    justify-content: space-between;
                    .time_left{
                        display: flex;
                        align-items: center;
                        width: 100%;
                        span{
                            font-size: 14px;
                            margin-left: 10px;
                            font-weight: 600;
                        }
                        .clock-icon{
                            width: 22px;
                        }
                    }
                  
                }
                .time_right{
                    font-size: 10px;
                    color: #1CB400;
                    position: relative;
                    margin-top: 6px;
                    margin-left: 10px;
                    cursor: text;
                    &::before{
                        content: '';
                        width: 5px;
                        height: 5px;
                        background-color: #1CB400;
                        position: absolute;
                        border-radius: 100%;
                        top: 3px;
                        left: -10px;
                    }
                }
                .title-wrap{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 5px;
                   .medication-title{
                        color: #34434E;
                        font-size: 14px;
                        flex: 3;
                    }
                    .blue-button{
                        margin-right: 0!important;
                        padding: 3px 18px;
                        flex: 1;
                    }
                    .btn-join{
                        padding: 3px 14px;
                    }
                }
                .rout{
                    font-size: 12px;
                    color: #34434E;
                }
                .left-section{
                    .location{
                        margin-bottom: 0;
                    }
                }
               .right-section{
                   display: flex;
                   flex-direction: column;
                   .resize-icon{
                       align-self: flex-end;
                       margin-bottom: 20px;
                   }
               }
               .taken-dosages{
                   margin: 0;
                   font-size: 12px;
                   color: #000;
               }
            }
           
        }
        .location span{
            display: flex;
        }
}

.location{
    margin-top: 5px;
    margin-left: -5px;
    margin-bottom: 8px;
   
    img{
        width: 20px;
        height: 20px;
    }
    span{
        font-size: 12px;
        color: #575757;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        align-items:center;
        display: block;
        font-weight: bold;
        cursor: pointer;
        &:hover{
           color: $link-color;
           transition: ease-in-out;
        }
    }
}

.noti-content-inner:nth-child(2n) .content-detail {
    background: #fff;
}
.noti-content-inner .content-date:after {
    content: '';
    position: absolute;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    border: 2px solid #0070C0;
    background: #fff;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
}
.noti-content-inner:nth-child(2n) .content-date:after {
    border: 2px solid #FF8029;
}
.noti-content-inner .content-date:before {
    content: '';
    position: absolute;
    height: 130%;
    width: 1px;
    background: #E4E4E4;
    right: 22px;
    top: 44px;
}
.content-detail.active {
    background: rgb(0 112 192 / 12%) !important;
}
.content-detail.active:before {
    content: '';
    width: 6px;
    height: 6px;
    background: #0070C0;
    position: absolute;
    top: 35px;
    border-radius: 50%;
    left: 15px;
}
.dash-noti::-webkit-scrollbar, .dash-reminder::-webkit-scrollbar, 
.my-document .tab-wrapper .table-main-wrap::-webkit-scrollbar, 
.my-notifiation .my-notification-inner .dash-noti-content::-webkit-scrollbar, 
.site-info-table::-webkit-scrollbar, ::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .dash-noti::-webkit-scrollbar-track, .dash-reminder::-webkit-scrollbar-track, 
  .my-document .tab-wrapper .table-main-wrap::-webkit-scrollbar-track, 
  .my-notifiation .my-notification-inner .dash-noti-content::-webkit-scrollbar-track, 
  .site-info-table::-webkit-scrollbar-track, ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey; 
    border-radius: 8px;
  }
  .dash-noti::-webkit-scrollbar-thumb, .dash-reminder::-webkit-scrollbar-thumb, 
  .my-document .tab-wrapper .table-main-wrap::-webkit-scrollbar-thumb, 
  .my-notifiation .my-notification-inner .dash-noti-content::-webkit-scrollbar-thumb, 
  .site-info-table::-webkit-scrollbar-thumb, ::-webkit-scrollbar-thumb {
    background: $dark-blue;
    border-radius: 8px;
  }
  .dash-noti::-webkit-scrollbar-thumb:hover, .dash-reminder::-webkit-scrollbar-thumb:hover, 
  .my-document .tab-wrapper .table-main-wrap::-webkit-scrollbar-thumb:hover, 
  .my-notifiation .my-notification-inner .dash-noti-content::-webkit-scrollbar-thumb:hover, 
  .site-info-table::-webkit-scrollbar-thumb:hover, ::-webkit-scrollbar-thumb:hover {
    background: $dark-blue; 
  }

:root{
   scrollbar-color: $dark-blue gainsboro;;
  scrollbar-width:thin; 
}
div{
    scrollbar-width:thin; 
}
#Group_1042, #Group_1043, #Group_1044, #Group_1791{
  -webkit-animation: dash 3s infinite linear;
  -moz-animation: dash 3s infinite linear;
  -o-animation: dash 3s infinite linear;
  animation: dash 3s infinite linear;
}
@-webkit-keyframes dash {
  to { stroke-dashoffset: -100; }
}
@-moz-keyframes dash {
  to { stroke-dashoffset: -100; }
}
@-o-keyframes dash {
  to { stroke-dashoffset: -100; }
}
@keyframes dash {
  to { stroke-dashoffset: -100; }
}
.topbar {
    width: 100%;
}
.topbar #kt_quick_search_toggle{
    width: 34%;
    margin: 10px 2% 0 0 !important;
    position: relative;
}
.social-icons a:first-child {
    margin-left: 0 !important;
}

@include media-breakpoint-down(lg){
body .personal-info-right.col-xl-9 {
    margin: 0 0 4% 0 !important;
    max-width: 100% !important;
}
body .personal-info-left {
    margin: 0;
    border: 0 !important;
}
.vertical-line{
    margin-right: 10px;
}

}
@include media-breakpoint-down(md){
    .dash-top-right {
        padding-left: 30px;
    }
    .dashboard-content, .dash-reminder{
        width: 100% !important;
    }
    .reminder-alert p{
        font-size: 12px;
    }
    .login-root{
        padding: 30px;
    }
    #splash-screen img{
        max-width: 75% !important;
        width: 100% !important;
    }
    div#dashcircle img {
        max-width: 100% !important;
        width: 30px !important;
    }
    body .perosnl-info-success .MuiPaper-root.MuiDialog-paper{
        width: 90% !important;
    }
    body .uplaod-profile.info-popup .MuiPaper-root.MuiDialog-paper {
        width: 90% !important;
        max-width: 90% !important;
    }
    
    .middle-content {
        width: 100%;
    }
    .mobile-icons button#kt_header_mobile_topbar_toggle {
        display: none;
    }
    .tabs-document ul li{
        width: 100% !important;
        a{
            border: 0 !important;
        }
    }
 
    
}
.my-document{
    .stipend-filter{
        .select-box{
            input[type=date]{
                padding-right: 10px;
            }
        }
        .Date-button{
            display: flex;
        }
    }
}
@include media-breakpoint-down(sm){
    .social-icons button {
        max-width: 45px;
    }
    .social-icons a{
        max-width: 35px; 
    }
    .dash-top .dash-top-left, .dash-top .dash-top-right, .dash-noti, .dash-reminder{
        width: 100% !important;
        text-align: left;
    }
    .dash-top .dash-top-right{
        margin-top: 10px;
        padding-left: 0;
    }
    .dash-noti{
        margin-bottom: 15px !important;
    }
    .dash-event-inner {
        width: 100% !important;
    }
    body .my-account-wrapper .country-name, body .my-account-wrapper .state, body .my-account-wrapper .city{
        width: 100% !important;
        margin:0 !important;
    }
}
//notification//
.my-notifiation {
    float: left;
    width: 100%;
    .my-notification-inner {
        padding: 10px;
        flex-grow: 1;
        border-radius: 10px;
        background-color: #fff;
        width: 100%;
        float: left;
        height: calc(100vh - 144px);
        .search-box {
            float: right;
            margin: 0;
        }
        h3{
            margin-top: 10px;
            float: left;
        }
        .dash-noti-content{
            height: calc(100vh - 230px);
            overflow-y: auto;
        }
    }
}
.btn-success {
    background-color: #0070bf !important;
    border-color: #0070c0 !important;
}
.nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-success .nav-link:hover:not(.disabled), .nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-success .nav-link.active, .nav.nav-tabs.nav-tabs-line.nav-tabs-line-active-border-success .show > .nav-link{
    border-bottom-color: #0070bf !important;
}
//topbar//
div#kt_quick_search_toggle{
    margin: 10px 15px 0 10px !important;
}
.select-dropdown {
    width: calc(100% - 95px);
    height: 45px;
    background: #F0F2F5;
    border-radius: 25px !important;
    margin: 0 !important;
    display: inline-block !important;
    vertical-align: top;
    text-align: left;
    option {
        color: black;
        font-weight: 400;
        font-size: 12px;
        padding: 5px !important;
        min-height: 20px;
    }
    button {
        border: 0;
        background: transparent;
        width: 100% !important;
        text-align: left !important;
        display: block !important;
        padding: 10px !important;
        font-size: 15px !important;
        font-weight: 100 !important;
        color: #494E56 !important;
    }
    button#dropdown-split-basic {
        position: absolute;
        background: transparent !important;
        right: 10px;
        top: 0;
        width: auto !important;
    }
    .dropdown-toggle:after {
        color: #000 !important;
    }
    
}
div[data-tut="My_top_notification"] {
    width: 44px;
    margin-top: 10px !important;
    display: inline-block !important;
    vertical-align: top;
    margin-right: 0;
    position: relative;
}
div[data-tut="my_profile"] {
    margin-top: 10px;
    width: calc(100% - 54px);
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block !important;
    vertical-align: bottom;
    margin-left: 5px;
    background: #f0f2f5;
    border-radius: 8px;
}
.top-info {
    width: 28px;
    margin-top: 20px;
    text-align: left;
    display: inline-block;
    margin-right: 2%;
    vertical-align: middle;
    
img {
    max-width: 100%;
}
}
.select-dropdown .MuiInputBase-root {
    width: 97%;
    padding: 10px 6px 10px 0;
    border: 0;
    select{
        font-size: 15px;
        background: transparent !important;
        padding: 6px 15px;
    }
}
.select-dropdown .MuiInputBase-root:before, .MuiInput-underline:after{
    display: none !important;
}
.hidefooter{
    display: none !important;
}
#pdf-zoom-out,#pdf-zoom-in{
    display: none !important;
}
.uplaod-profile .MuiDialog-paperWidthSm {
    max-width: 500px !important;
}
.MuiPaper-root.MuiDialog-paper {
    width: 507px !important;
}
.uplaod-profile .MuiDialog-paperWidthSm .middle-content {
    padding: 0 !important;
    width: calc(100% - 40px);
    span {
        color: #47A400;
    }
    .middle-content {
        margin: 0 0 0 0 !important;
        padding: 0 !important;
        width: 100%;
        border: 0;  
    }
}
.btn.btn-danger {
    color: #ffffff;
    background-color: #ff5858 !important;
    border-color: #ff5858 !important;
}
.navi .navi-item .navi-link .navi-text {
    color: #3F4254;
    display: block;
}
.card-custom{
 .card-body {
    padding: 15px !important;
}
.card-header {
    padding: 10px 15px !important;
}
}
.my-account-wrapper .city {
    margin-right: 0 !important;
}
.my-account-wrapper .country-name, .my-account-wrapper .state, .my-account-wrapper .city {
    width: 33% !important;
}
.bg-success {
    background-color: #e7c235 !important;
}
.account-info{
.account-label {
    margin-right: 10px !important;
    width: 70px;
    float: left;
    display: block;
}
.account-value {
    width: calc(100% - 70px);
    float: left;
    word-break: break-word;
}
}
.dropdown-user-menu{
    width: 300px !important;
}
.aside-fixed.aside-minimize.aside-minimize-hover .aside{
    width: 70px !important;
}
.aside-minimize.aside-minimize-hover li a span:last-child, 
.aside-fixed.aside-minimize.aside-minimize-hover .aside-menu .menu-nav > .menu-item > .menu-link .menu-text {
    display: none;
}
.sc-gKAaRy {
    width: 100%;
    float: left;
    margin: 0 0 20px 0;
}
.city, .country, .state{
    width: 100% !important;
    margin-right: 3% !important;
}
.country{
    margin-bottom: 35px;
    margin-right: 0 !important;
}
.county-code.city {
    margin-right: 0 !important;
}
.spinner-border {
    margin: 0px auto 10px auto;
    width: 20px !important;
    height: 20px !important;
    color: $link-color!important;
}
p.logut-content {
    text-align: center;
}
.status-wrap{
    display: flex;
    align-items: center;
    justify-content: center;
}
.symbol-label img {
    max-width: 100%;
    height: 100% !important;
    width: 100% !important;
    max-height: 100%;
    object-fit: cover;
}
.image-input {
    vertical-align: top !important;
}
.left-side .image-input-wrapper {
    width: auto !important;
    height: auto !important;
}
div#kt_header {
    box-shadow: 0px 0px 40px 0px rgb(82 63 105 / 10%) !important;
}
.topbar-item .symbol-label img{
    width: 35px !important;
    height: 35px !important;
    object-fit: cover;

}
.info-heading {
    font-size: 22px;
    font-weight: 600;
    color: #494E56;
}
.spinner-info {
    margin: 10px auto !important;
    width: 24px !important;
    height: 24px !important;
}
.info-desc {
    font-size: 15px;
    margin-bottom: 5px;
    letter-spacing: 0.5px;
    span{
        font-weight: 600;
        font-size: 14px;
    }
}
.info-popup .middle-content {
    border: 0;
    margin: 0 !important;
}
.top-info i{
    color: #4f697e;
    font-size: 26px;
    cursor: pointer;
}
div#kt_header .container-fluid {
    padding-right: 15px;
}
.topbar-search {
    width: calc(100% - 250px);
    float: left;
    #user-notification-tooltip{
        z-index: 999999;
        background-color: transparent;
    }
   
    .search-wrap{
        input{
            border-bottom: none !important;
            padding-top: 10px!important;
            padding-left: 10px!important;
        }
        .search-box{
            border-radius: 100px;
            width: 100%!important;
            text-align: left;
            padding-left: 10px;
            color: black!important;
            cursor: text;
            padding-right: 63px !important;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 12px!important;
            &:hover &:focus{
                color: #000;
            }
          
          }
          .spinner-info{
            width: 10px !important;
            height: 10px !important;
            position: absolute;
            top: 5px;
            right: 60px;
            z-index: 99;
        }
    }
    .search-wrap{
        position: relative;
        .close-icon-btn{
            position: absolute;
            top: 12px;
            right:34px;
            border: none;
            background-color: transparent;
            cursor: pointer;
            .close-icon{
                width: 18px;
            }
        }
        .search-icon-btn{
            position: absolute;
            top: 0px;
            height: 100%;
            right:0px;
            padding: 0 8px;
            border: none;
            background-color: #d7dbdc;
            cursor: pointer;
            border-top-right-radius: 100px;
            border-bottom-right-radius: 100px;
            .search-icon{
                width: 18px;
            }
        }
    }
    
}
.topbar-info {
    width: 250px;
    float: right;
    text-align: left;
}
.success-popup .success-icon .icon {
    color: #47A400;
    border: 4px solid #47A400;
}
button[data-tour-elem="right-arrow"] button {
    background: #0070C0;
    border: 0;
    padding: 10px 15px;
    border-radius: 5px;
    font-size: 13px;
    color: #fff;
}
button[data-tour-elem="left-arrow"] button {
    background: transparent;
    border: 0;
    color: #E7C235;
    font-size: 13px;
}
div[aria-describedby="user-notification-tooltip"]{
    text-align: left;
    background-color: white;
    background: white;
    opacity: 1.0;
    filter: alpha(opacity=100);
}
div#kt_profile_aside .card.card-custom, .change-pass-form, .my-account-wrapper .card.card-custom {
    margin-bottom: 50px;
    display: block;
    height: auto;
    height: calc(100vh - 147px);
    overflow: auto;
}
.change-pass-form{
    button[type="submit"]{
        background-color: $btn-primary;
        border-color: $btn-primary;
        &:hover{
            background-color: $btn-primary!important;
        border-color: $btn-primary!important;
        }
    }
}
// profile//
.upload-pic {
    text-align: center;
    .uplaod-button-wrap {
        display: block;
        .middle-content {
            margin: 0 0 20px 0 !important;
        }  
        .upload-text {
            text-align: center;
        }  
    }
}
.perosnl-info-success .MuiPaper-root.MuiDialog-paper {
    width: 70% !important;
    max-width: 100% !important;
}
.perosnl-info-success .MuiPaper-root.MuiDialog-paper .MuiSelect-select {
    text-align: left;
}
.topbar #kt_quick_search_toggle .dropdown-menu {
    width: 100% !important;
    left: 0 !important;
    transform: translate(0, 46px) !important;
}
div[data-tut="My_top_notification"] form h4 span.text-white {
    line-height: 32px;
    display: block;
}
.aside-menu .menu-nav .menu-item a.active path {
    stroke: $link-color;
}
.footer{
    position: fixed !important;
    z-index: 111;
}
.my-account-wrapper.personal-account input, .my-account-wrapper.personal-account select {
    border-bottom: 0 !important;
    appearance: none;
}
.my-account-wrapper.personal-account .save-btn{
    width: auto !important;
}
.my-account-wrapper.personal-account .MuiInput-underline:before, 
.my-account-wrapper.personal-account .MuiInput-underline svg{
    display: none;
}
.my-account-wrapper.personal-account .form-group {
    margin-bottom: 10px !important;
}
.my-account-wrapper.personal-account p.MuiFormHelperText-root {
    margin-bottom: 10px;
}
.my-account-wrapper.personal-account .Mui-disabled.Mui-disabled {
    color: #000 !important;
}
.my-account-wrapper.personal-account .image-input label{
    display: none;
}
.my-account-wrapper{
    .progress-bar{
        display: none;
    }
}
.change-detail-popup {
    position: relative;
    textarea {
        border-radius: 5px;
        height: 80px;
        width: 100%;
        float: left;
    }
    button {
        margin: 10px 0 0 0 !important;
        float: right;
    }
}
.popup-heading {
    padding: 6px 8px !important;
    border-bottom: 1px solid #dfdfdf;
    background-color: $dark-blue;
    color: #fff;
    h6 {
        letter-spacing: 0.5px;
        padding: 5px 10px;
        border-radius: 10px;
        font-size: 15px;
    }
}
.my-account-wrapper.personal-account .card-body {
    padding: 0 15px !important;
}
.my-account-wrapper.personal-account .personal-info-right .form-group {
    border-bottom: 1px solid #EBEDF3;
    margin: 0 !important;
    border-left: 0px solid #ebedf3;
    border-right: 0px solid #ebedf3;
    padding: 15px 5px 8px 15px;
    min-height: 57.81px;
}
.border-top{
    border-top: 1px solid #EBEDF3;
    
}
.display-none{
    display: none !important;
}
.education-head{
    color: #494E56;
    font-weight: bold;
    font-size: 15px;
    margin-bottom: 40px;
}
.my-account-wrapper{
    .borderLeft {
        border-left: 1px solid #ebedf3 !important;
    }
}

.my-account-wrapper.personal-account .country-name, 
.my-account-wrapper.personal-account .state, 
.my-account-wrapper.personal-account .city {
    width: 33.33% !important;
}
.my-account-wrapper.personal-account .personal-info-right .col-lg-6:nth-child(2n-1) .form-group {
    border-right: 0 !important;
}
.my-account-wrapper.personal-account .personal-info-right .col-lg-12 .country-name, 
.my-account-wrapper.personal-account .personal-info-right .col-lg-12 .state {
    border-right: 0 !important;
}
.my-account-wrapper.personal-account .personal-info-right .form-group .address {
    margin: 0 !important;
}
.my-account-wrapper.personal-account .change-detail-btn{
    padding: 0;
}
.my-account-wrapper.personal-account .change-detail-btn button{
    float: left;
    margin-right: 15px;
}

.my-account-wrapper.personal-account .personal-info-right .col-lg-6, 
.my-account-wrapper.personal-account .personal-info-right .col-lg-12 {
    padding: 0 !important;
}
.my-account-wrapper.personal-account .image-input img {
    width: 80px !important;
    height: 80px !important;
    border-radius: 8px;
}
.my-account-wrapper.personal-account .left-side {
    margin-bottom: 12px;
}
.personal-info-left{
    border: 1px solid #EBEDF3;
    max-height: 290px;
    margin-left: 2%;
    margin-top: 2%;
}
.personal-info-right.col-xl-9 {
    margin: 0 2% 4% 2%;
    max-width: 69% !important;
}

//firefox close button remove
.personal-info-right{
    input[type="date"]{
        border: none;
        clip-path: inset(0 17px 0 0);
        outline:none;
        outline:0;
      }
      .name-input{
        input{
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
        }
      }
}
     
.profile-wrapper{
    text-align: center;
    margin-top: 22px;
    background: #e4e4e4;
    height: 100px;
    width: 100px;
    border-radius: 100px;
    margin: 15px  auto;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    span{
        text-transform: uppercase;
        font-weight: bold!important;
        font-size: 30px!important;
    }
    .profile-upload-wrap{
        .upload-button{
            position: absolute;
            bottom: 0;
            right: 0;
        }
        img{
            width: 100px!important;
            height: 100px!important;
           border-radius: 100px !important;
           object-fit: cover;
        }
    }
}
.uplaod-profile-popup, .contact-person{
    z-index: 9999!important;
}
.uplaod-profile-popup{
    .middle-content {
        padding:10px
    }
}
.my-profile-img{
   .img{
    border-radius: 8px;
  }
}
input[type="time"]::-webkit-clear-button {
    -webkit-appearance: none; 
    -moz-appearance: none;
    appearance: none;
}
input[type=text]::-ms-clear {
    display: none;
}
.country-name{
    border-top: 1px solid #EBEDF3 !important;

}
.SiteInfo{
    border-bottom: 1px solid #EBEDF3 ;
    margin-bottom: 15px;
    h3{
        margin-bottom: 10px;
    }
}
.site-list{
    padding-left: 20px;
    margin: 10px auto;
    li{
     text-align: left!important;
    }
}
// my tickets//
.dash-reminder.my-tickets {
    width: 100%;
}
.dash-reminder.my-tickets .reminder-alert {
    width: 32%;
    float: left;
    margin-right: 2%;
}
.dash-reminder.my-tickets .reminder-alert:last-child, 
.dash-reminder.my-tickets .reminder-alert:nth-child(4) {
    margin-right: 0;
}
.invalid-feedback {
    text-align: left;
}

button[data-tour-elem="left-arrow"],button[data-tour-elem="right-arrow"] {
    color: #E7C235;
    font-size: 13px;

    svg:not(:root){
        margin-bottom: 10px;
    }
}

//info popup//
.info-popup{
button {
    margin: 0 !important;
}
.MuiDialogActions-spacing {
    float: right;
    align-items: end !important;
    justify-content: flex-end !important;
}
}
.info-popup.uplaod-profile .middle-content {
    padding: 15px 10px !important;  
    .site-info, .site-info-title {
            width: 100%;
            float: left;
            img{
                max-height: 41px;
                margin-right: 5px;
                position: relative;
                margin-bottom:8px;
                }
            h5 {
                width: 100%;
                float: left;
                background: #f3f3f5;
                padding: 10px;
                text-align: left;
                border-radius: 5px;
                font-size: 14px;
                text-transform: uppercase;
                font-weight: 800;
                color: #0070c0;
                letter-spacing: 1px;
                border: 1px solid #0070c0;
            }
            .site-info-content {
                width: 100%;
                float: left;
                padding: 10px;
               
            }
            .dite-info-left {
                width: 100%;
                float: left;
                margin-right: 0;
                text-align: left;
                label {
                    float: left;
                    font-weight: 600;
                    word-break: break-word;
                    width: 195px;
                    margin-right: 10px;
                    font-size: 13px;
                }
                span {
                    width: calc(100% - 205px);
                    float: right;
                    display: block;
                    word-break: break-word;
                    font-size: 13px;
                }
            }
            .dite-info-left:nth-child(2n) {
                margin-right: 0;
            }
        }
}

.select-study-drop {
    width: 60%;
    margin: 10px 2% 0 0 !important;  
    display: inline-block !important;
    vertical-align: top;
    position: relative;
    .Custom-tooltip{
        font-size: 12px;
        color: #3f4254;
        position: absolute;
        display: none;	
        text-align: center;
        background-color: #fff;
        border-radius: 4px;
        top: 50px;
        left: 40%;
        padding: 10px;
        box-shadow: 3px 4px 22px -1px rgba(0,0,0,0.26);
        -webkit-box-shadow: 3px 4px 22px -1px rgba(0,0,0,0.26);
        -moz-box-shadow: 3px 4px 22px -1px rgba(0,0,0,0.26);
        z-index: 1;
    }
    &:hover{
        .Custom-tooltip{
            display: block;
        }
    }
    label {
        width: 95px;
        display: inline-block;
        vertical-align: middle;
        margin-top: 12px;
        text-align: left;
    }
}
div[data-tut=my_profile] .topbar-item {
    display: block !important;
    text-align: left;
}
.MuiNativeSelect-select.MuiNativeSelect-select{
    font-size: 13px !important;
}
//my tickets//
.navi .navi-item .navi-link .navi-icon svg path ,//my tickets//
.navi .navi-item .navi-link .navi-icon svg line{
    stroke: #b5b5c3;
    fill: #b5b5c3;
}
.navi .navi-item .navi-link.active .navi-icon svg path, 
.navi .navi-item:hover .navi-icon svg path {
    stroke: $link-color!important;
    fill: $link-color!important;
}

.alert.alert-custom.alert-light-danger {
    padding: 5px 10px !important;
}
div[data-tut=my_profile] .topbar-item span {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.uplaod-profile.info-popup .MuiPaper-root.MuiDialog-paper {
    width: 800px !important;
    max-width: 800px !important;
}
.dash-top{
    text-align: center;
    img {
        max-width: 200px;
        margin-bottom: 15px;
    }
}
.dash-top p {
    margin: 0;
    text-align: center;
    font-weight: 600;
    color: #494E56;
    text-transform: capitalize;
}
//notification//
.search-box {
    font-size: 14px;
    color: #9E9E9E;
    border-radius: 10px;
}
.my-profile-img {
    width: 50px;
    margin-right: 15px !important;
    float: left;
    display: block;
    span{
        text-transform: uppercase;
    }
}
.my-profile-text {
    width: calc(100% - 65px);
    word-break: break-word;
    float: left;
    display: block;
    white-space: normal;
}
div#kt_profile_aside .my-profile-img{
    width: 60px;
}
div#kt_profile_aside .my-profile-text{
    width: calc(100% - 75px);
}
.my-account-wrapper.personal-account input {
    padding-bottom: 0 !important;
    height: auto !important;
}
.subheader-mobile {
    display: none;
}
div[data-tut="my_profile"] .header-user span {
    text-align: left;
}
div[data-tut="my_profile"] .header-user span.text-muted {
    margin-left: 5px;
    width: 20px;
}
.my-account-wrapper.personal-account form {
    margin-bottom: 50px;
}
div#kt_profile_aside .my-profile-img .symbol-label {
    width: 60px !important;
    height: 60px !important;
}

.select-dropdown .MuiSelect-select {
    background: transparent !important;
    padding: 5px 24px 5px 20px;
}

.tabs-document {
    width: 100%;
    float: left;
    ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        
        li {
            width: 33.33%;
            float: left;
            text-align: center;
            background: #e0e6ea;
            padding: 10px 0;
            a {
                color: #34434E;
                font-size: 14px;
                padding: 10px 0;
                display: block;
                height: 40px;
                border-right: 1px solid #bcbcbc;
                img{
                    margin-right: 10px;
                }
            }
        } 
        li:last-child a{
            border: 0;
        }
        li.active {
            background: #01aac9;
            a{
                color: #fff;
                border: 0;
            }
        }   
    }

}
//notification-dropdown
.topbar-info{
    .dropdown-menu.dropdown-menu-lg{
        width: 430px;
    }
}
.mobile-view{
    .check_box_container{
        .check_box_wrap{
            label{
                line-height: inherit;
            }
        }
    }
} 
.notification-dropdown {
    width: 100%;
    float: left;
    padding: 0 15px;
    position: relative;
    max-height: 450px;
    min-height: 120px;
    overflow-y: auto;
    .notify-inner {
        width: 100%;
        float: left;
        border-bottom: 1px solid #DEDEDE;
        padding: 14px 0;
        img {
            max-width: 45px;
            margin-right: 10px;
            float: left;
        }
        .notify-content {
            width: calc(100% - 55px);
            float: left;
            p {
                font-size: 13px;
                margin-bottom: 3px;
            }
            span {
                font-size: 13px;
                font-weight: 100;
            }
            
            
        }
    }
    .notify-inner.no-notications {
        text-align: center;
        img{
            float: none !important;
            margin: 0 auto 5px auto !important;
            text-align: center;
        }
        .notify-content {
            width: 100%;
            text-align: center;
            p {
                text-transform: capitalize;
                font-weight: 600;
                font-size: 14px !important;
            }
        }
    }

    .notify-see {
        position: fixed;
        bottom: -33px;
        width: 100%;
        left: 0;
        text-align: center;
        a {
            display: block;
            background: $btn-primary;
            border: 1px solid $btn-primary;
            color: #fff;
            font-size: 14px;
            padding: 10px 0;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
    }
}
.fa-exclamation-triangle{
    width: 30px !important;
    color:#ff5858!important;
}
.header-user .symbol-label{
    margin-right: 4px !important;
}
.makeStyles-root-23{
    height: calc(100vh - 144px);
}
.info-popup .site-info-title .site-info-content {
    padding-top: 0 !important;
    h2 {
        font-size: 16px;
        font-weight: 600;
        letter-spacing: 1px;
    }
}
.site-info-table{
    width:100%;
    float: left;
    overflow-x: auto;
    margin-bottom: 15px;
}
.site-info-title table {
    width: 100%;
    float: left;
    border: 1px solid #0070c0;
    border-radius: 8px !important;
    tr:first-child th {
        background: #0070c0;
        color: #fff;
        text-align: center;
        padding: 5px;
    }
    tr:nth-child(2) th {
        background: #f3f3f5;
    }
    tr td, tr th {
        text-align: left;
        padding: 5px 10px;
        font-size: 12px;
        width: 150px;
        max-width: 150px;
        word-break: break-word;
        min-width: 150px;
    }
}
.footer span a {
    color: #ffff;
}
.footer .footer-img, .copyright-footer .footer-logo {
    position: relative;
    text-align: left;
    max-height: 27px;
    padding-right: 15px;
    border-right: 1px solid #fff;
    margin-right: 15px;
}
p.copyright-footer, p.copyright-footer a {
    color: #fff;
}
.copyright-footer-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding:20px 20px 0px 20px;
    flex-direction: column;
    gap: 10px;
    .copyright-footer{
        margin: 0;
    }
    .appstore-icon-wrap{
        display: flex;
        gap: 10px;
        img{
            width: 120px;
        }
    }
}
.contact-person p.success-text {
    margin: 0 0 10px 0 !important;
}
.contact-person h2 {
    margin: 8px 0 0 0;
    font-weight: 600;
    font-size: 18px;
}
.contact-person .success-icon img {
    max-width: 50px;
    margin-bottom: 15px;
}
.register-button span {
    font-size: 14px;
    color: #fff;
    font-family: "Poppins";
}
.jss1 {
    width: 100%;
    float: left;
}
.document-inner{
    padding: 10px;
    flex-grow: 1;
    border-radius: 10px;
    background-color: #fff;
    width: 100%;
    float: left;
}
.my-document .tab-wrapper .table-wrapper tbody td:first-child {
    max-width: 170px;
    word-break: break-word;
    white-space: normal;
    min-width: 120px;
}
.my-document .tab-wrapper .table-wrapper tbody td:nth-child(2), 
.my-document.help-support .tab-wrapper .table-wrapper tbody td:nth-child(3) {
    max-width: 170px;
    word-break: break-word;
    white-space: normal;
    min-width: 120px;
}
.site-info-content span {
    font-weight: 300;
    color: #000 !important;
}
.site-info-mail {
    width: 100%;
    float: left;
}
.site-info-mailbox {
    display: inline-block;
    vertical-align: middle;
    padding-right: 30px;
    margin-right: 30px;
    border-right: 1px solid #D2D2D2;
}
.site-info-mailbox.without-border {
    border: 0;
    padding: 0;
    margin: 0;
}
.site-info-mailbox img {
    margin: 0 8px 0 0 !important;
    max-height: inherit;
}
.site-info-title {
    border-bottom: 1px solid #D2D2D2;
    padding: 12px 0;
    
}
.middle-content {
    .site-info-wrap{
        .site-info-title {
            text-align: center;
            .site-info-mail{
                float: none;
            }
            img{
                margin-bottom: 10px;
            }
        }
        .site-info-content{
            .site-logo{
                width:300px;
            }
        }
    }
  
 
}
.site-info-left {
    display: inline-block;
    vertical-align: middle;
    width: 150px;
    height: 100%;
}
.site-info-left p{
    font-weight:600;
    margin:0;
}
.site-info-right {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 150px);
    border-left: 1px solid #D2D2D2;
    text-align: left;
    padding-left: 40px;
}
.site-info-right span {
    font-weight: 300;
    color: #000 !important;
}
.site-info-right p{
    font-weight:600;
    margin: 0 0 8px 0;
}
.site-info-content p span {
    font-weight: 600;
    margin-right: 5px;
}
.view-file-popup .MuiPaper-root.MuiDialog-paper {
    width: 70% !important;
    max-width: 100% !important;
}
.view-file-popup .pg-viewer-wrapper {
    overflow-y: auto !important;
}
.view-file-popup .pg-viewer-wrapper canvas {
    width: auto !important;
    height: auto !important;
    max-width:100%;
}
.address label {
    transform: translate(0, 1.5px) scale(0.75);
    transform-origin: top left;
}
body table td button {
    padding: 0 !important;
    box-shadow: none !important;
    border: 0 !important;
}
body table td button:after{
    display: none !important;
}
body table td .dropdown-menu a{
    font-size: 13px;
    color: #34434E;
    line-height: 30px;
}
body table td .dropdown-menu img{
    margin-right: 10px;
}
.css-yk16xz-control {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-radius: 0 !important;
}
.css-g1d714-ValueContainer,.css-1hwfws3 {
    padding-left: 0 !important;
}
.css-1uccc91-singleValue {
    font-weight: 400 !important;
    font-size: 14px !important;
}
span.css-1okebmr-indicatorSeparator {
    width: 0 !important;
}
  .css-1pahdxg-control {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid #E4E6EF !important;
    box-shadow: none !important;
    border-radius: 0 !important;
}
.content-time p {
    margin-bottom: 8px;
}
.content-time span{
    font-size: 11px;
}
.my-document i {
    cursor: pointer;
}
.my-document .dropdown-menu {
    left: auto;
    right: 0;
    .dropdown-item{
        margin-bottom: 18px;
        align-items: center;
        &:last-child{
            margin-bottom: 0;
        }
    }
}
.view-file-popup .pg-viewer-wrapper {
    background: #d3d6d0d7;
}
.popup-heading {
    position: relative;
}
.popup-heading .popup-buttons {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 35px;
}
.popup-heading .popup-buttons i {
    color: #fff;
    font-size: 18px;
    margin-right: 20px;
    cursor: pointer;
}
.select-study-drop .MuiSelect-root.MuiSelect-select p {
    float: left;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    color: #000;
    font-weight: 500;
}
.uplaod-button-wrap {
    padding: 30px !important;
}
.success-icon{
    margin-top: 12px;
}
.my-stipend {
    width: 100%;
    float: left;
    padding: 15px;
    background: #fff;
    border-radius: 16px;
}
.my-stipend-inner {
    width: 100%;
    display: flex;
    border: 1px solid rgb(112 112 112 / 30%);
    border-radius: 8px;
}
.left-text-wrapper{
    background: #EBEDF3;
    align-items: center;
    flex: 1;
    padding-top: 5px;
    padding-bottom: 5px;
    display: flex;
    flex-direction: column;
    .left-text{  
        font-size: 20px;
        font-weight: 700;
        }
   
}
.stipend-filter{
    .right-section{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        flex: 5;
        .MuiInput-underline:hover:not(.Mui-disabled):before,.MuiInput-underline:before{
            border-radius: 10px;
            border-bottom: 1px solid #CECECE !important;
        }
        
    }
    .validation-msg{
        color: red;
        padding-right: 10px;
        padding-left: 10px;
    }
    
}
.middle-content-wrap{
    background-color: #fff;
    border-radius: 10px;
    padding-bottom: 20px;
    margin-bottom: 60px;
}
.my-stipend-top {
    flex: 1;
    border-right: 1px solid #E3E3E3;
    text-align: center;
    padding: 10px 0;
    align-items: center;
    min-height: 65px;
    .error-msg{
        font-size: 14px;
    }
    img{
        width: 110px;
        margin-top: 8px;
    }
}
.my-stipend-top span {
    color: #34434E;
    font-size: 20px;
    font-weight: 600;
}
.my-stipend-top p {
    margin: 0;
    color: #494E56;
    font-size: 13px;
    font-weight: 100;
}
.stipend-earn {
    width: 100%;
    float: left;
    text-align: center;
    padding: 12px 0;
}
.stipend-earn p {
    font-size: 13px;
    margin-bottom: 0;
}
.stipend-earn select {
    height: 36px;
    background: #F0F2F5;
    border-radius: 16px;
    margin: 0 10px;
    font-size: 13px;
    color: #494E56;
    padding: 0 10px;
}
.stipend-earn span {
    color: #34434E;
    font-size: 21px;
    font-weight: 600;
    top: 3px;
    left: 5px;
    position: relative;
}
.my-stipend-tabs {
    width: 100%;
    float: left;
    margin: 15px 0 0px 0;
    background: #fff;
    border-radius:16px;
    padding: 15px;
    height: calc(100vh - 253px);
    overflow-y: auto;
    .dash-top {
        img{
            width: 10%;
        }
    }
}
.stipend-filter{
    margin: 15px 0 0 0;
    
}
.stipend-filter .select-box, .stipend-filter .btn-wrp{
    margin-bottom: 0;
}
.my-stipend-tab-inner{
    width: 100%;
    float: left;
}
div[data-tour-elem="controls"]{
    width: 100%;
    float: left;
    margin: 0 0 15px 0;
}
.my-document div#react-doc-viewer div#header-bar, div#pdf-controls a#pdf-download{
    display: none;
}

.doc-viewer{width: 100%; float: left; text-align: center;}
button.doc-back {
    background: #fdfdfd;
    border: 1px solid #fff;
    border-radius: 8px;
    font-size: 13px;
    padding: 5px 8px;
    margin-right: 10px;
    margin-bottom: 15px;
    float: left;
}
.doc-viewer span {
    font-size: 13px;
    font-weight: 600;
    position: relative;
    top: 10px;
}
.doc-print {
    float: right;
}
.doc-print button {
    background: #0070C0;
    border: 1px solid #0070C0;
    border-radius: 8px;
    font-size: 13px;
    padding: 5px 8px;
    color: #fff;
    margin-bottom: 15px;
}
div#react-doc-viewer {
    width: 100%;
    float: left;
}
.uplaod-profile .MuiDialog-paperWidthSm .MuiDialogContent-root.middle-content {
    overflow: unset;
}
.fade{
    opacity: 1 !important;
}
.alert.alert-custom .alert-icon{
    float: left;
    padding: 5px;
}
.alert.alert-custom .alert-text{
    float: left;
    padding: 4px 0;
    width:calc(100% - 70px);
}
.alert.alert-custom .alert-close{
    float: right;
    padding:18px 0;
}
.alert.alert-custom{
    float: left;
    width: 100%;
}
.react-pdf__Page__textContent{
    display: none;
}
.login-signin .alert-text {
    padding: 15px 0 !important;
}
.side-logo, .side-logo1 {
    position: relative;
    padding-bottom: 23.6%;

}
.side-logo img, .side-logo1 img{
    position: absolute;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.rbc-calendar .rbc-toolbar button {
    font-size: 14px;
    border-color: #CECECE !important;
    color: #000;
}
.rbc-calendar .rbc-toolbar span.rbc-toolbar-label {
    font-size: 15px;
    color: #000 !important;
}
.rbc-calendar .rbc-toolbar button.rbc-active {
    background: $link-color;
    box-shadow: none !important;
    border: 1px solid $link-color    !important;
}
.rbc-calendar .rbc-header span {
    display: block;
    font-size: 12px;
    font-weight: 600;
    text-align: left;
    padding: 14px 6px;
    text-align: center;
}
.rbc-calendar .rbc-month-view .rbc-month-row {
    height: 100px;
    max-height: 100px;
}
.custom-calendar{
    height: calc(100vh - 158px);
    position: relative;
    .date_picker{
        position: absolute;
        left: 54%;
        top: 21px;
        z-index: 9;
        border: none;
        background: transparent;
        cursor: pointer;
       input{
        opacity: 0;
        z-index: 9999;
        position: relative;
        -moz-clip-path: inset(0 30px 0 0);
       }
    }
   .picker-image{
    position: absolute;
    width: 20px;
    right: 0px;
    cursor: pointer;
   }
    .rbc-month-view{
        display: block;
        overflow-y: auto;
        max-height: 100%;
        min-height: 50%;
    }
    .rbc-day-slot .rbc-event-label{
        display: none;
    }
}
.rbc-calendar .rbc-month-view .rbc-month-row .rbc-date-cell a {
    font-weight: 100;
    font-size: 13px;
    color: #000;
    padding: 10px 15px;
    display: block;
    text-align: left;
}
.rbc-calendar .rbc-month-view .rbc-month-row .rbc-date-cell.rbc-off-range a {
    color: #CBCBCB;
}
.custom-calendar .rbc-calendar{
    min-height: 100%;
    max-height: 100%;
    height: 100%;
}
.rbc-row-content {
    z-index: 0;
}
.rbc-day-bg.rbc-off-range-bg {
    background: transparent;
}

.custom-calendar {
    width: 100%;
    float: left;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    border: 0;
    background: #fff !important;
    .rbc-timeslot-group{
        min-height: 60px;
    }
    .rbc-month-header{
        position: sticky;
        top: 0;
        background-color: #fff;
        z-index: 1;
    }
}

.rbc-event, .rbc-day-slot .rbc-background-event, .rbc-event.rbc-selected, .rbc-day-slot .rbc-selected.rbc-background-event {
    background: #f1f1f1;
    color: #494E56;
    font-size: 12px;
    border-radius: 0;
    border-left: 3px solid #E7C235;
    outline: 0;
    border-top: 1px solid #c7c7c7;
    border-bottom: 1px solid #c7c7c7;
    border-right: 1px solid #c7c7c7;
}
.rbc-event{
    border-left: 3px solid $link-color!important;
}
.rbc-today{
    .rbc-events-container{
        .rbc-event-label{
            display: none;
        }
    }
}
.rbc-time-view .rbc-row{
    min-height: auto !important;
}
body .uplaod-profile.info-popup.view-file-popup .MuiPaper-root.MuiDialog-paper{
    width: 90% !important;
    max-width: 90% !important;
}
.notification-dropdown .notify-inner:last-child .notify-content{
    margin-bottom: 35px;
}
.event-popup{
    padding: 0!important;
}

.calendar-meeting {
    float: left;
    width: 100%;
    position: relative;
    .visit-type{
        font-size: 16px;
        text-align: left;
        font-weight: bold;
        margin-bottom: 0px;
    }
    
    .meeting-date{
        clear: both;
    }
    .details-btn{
        background-color: transparent;
        border: none;
    }
    .top-section{
        background-color: #f0f0f0;
        padding: 15px;
        
        .location{
           
            .rout{
                display: block;
                text-align: left;
                white-space: normal;
                text-overflow: inherit;
            }
        }
        .heading-section{
            img{
                width: 15px;
                height: 15px;
            }
        }
        .join-button{
            padding: 5px 14px;
            margin: 15px 0 0 0;
            background: $btn-primary;
            border: 1px solid $btn-primary;
            font-size: 13px;
            border-radius: 8px;
            color: #fff;
        }
    }
    .bottom-section{
        padding-top: 15px;
        padding-bottom: 15px;
        .meeting-link{
            padding: 10px 15px 10px;
            align-items: center;
            .copy-icon{
                display: none;
                width: 18px;
                height: 18px;
                cursor: pointer;
            } a{
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                color: $btn-primary;

            }

            &:hover{
                background-color: #f0f0f0;
                a{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                }
            }
            img{
                margin-right: 15px;
                width: 24px;
                height: 14px;
            }
           
        }
    }
}
.calendar-meeting h1 {
    font-size: 15px;
    color: $btn-primary;
    text-align: left;
    position: relative;
    width: 100%;
    float: left;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 14px;
    
}
.calendar-meeting p {
    margin: 0;
    text-align: left;
    color: #494E56;
    margin-top: 6px;
}
.calendar-meeting button {
    margin: 8px 0 13px 10px;
    float: left;
    background: #0070C0;
    border: 1px solid #0070C0;
    font-size: 13px;
    color: #fff;
    border-radius: 8px;
    padding: 6px 10px;
}
.calendar-user {
    width: 100%;
    margin-bottom: 8px;
    display: flex;
}
.calendar-user img {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    border-radius: 50%;
}
.calendar-user .calendar-organizer {
    width: calc(100% - 34px);
    float: left;
}
.calendar-user .calendar-organizer p {
    font-size: 14px;
    margin-bottom: 6px;
}
.calendar-user .calendar-organizer span {
    text-align: left;
    display: block;
    font-size: 11px;
}
.calendar-user.organizer-user {
    border-top: 1px solid #ECE7E7;
    margin: 5px 0 0 0;
    padding-top: 10px;
    padding-left: 15px;
    padding-right: 15px;
}
.calendar-reminder .MuiPaper-root.MuiDialog-paper {
    width: 350px !important;
}
//new dashboard//
.dashboard-content {
    width: 66%;
    float: left;
    margin-right: 2%;
}
.todays-plan .reminder-text-wrap {
    width: 100%;
}
.todays-plan .reminder-text-wrap .reminder-text {
    width: calc(100% - 80px);
}
.todays-plan .reminder-text-wrap .right-arrr {
    width: 70px;
    float: left;
    margin-left: 10px;
}
.todays-plan .reminder-text-wrap .right-arrr h6 {
    color: #1CB400;
    font-size: 10px;
    position: relative;
    padding-left: 5px;
}
.todays-plan .reminder-text-wrap .right-arrr h6:before{
    position: absolute;
    content: '';
    left: -5px;
    top: 50%;
    background: #1CB400;
    transform: translateY(-50%);
    border-radius: 50%;
    height: 5px;
    width: 5px;
}
.todays-plan .reminder-text-wrap .right-arrr span, 
.todays-plan .reminder-text-wrap .right-arrr h6 {
    width: 100%;
    float: left;
}
.todays-plan .reminder-text-wrap .right-arrr span{
    text-align: right;
}
.todays-plan .reminder-text-wrap p {
    font-size: 13px;
    font-weight: 600;
}
.aside-minimize .side-logo1 {
    display: none;
}
.side-logo{
    display: none;
}
.aside-minimize .side-logo{
    display: block !important;
    width: 64px;
    padding-bottom: 0;
}
.aside{
    background: transparent !important;
}

.aside-minimize:not(.aside-minimize-hover) .aside-menu-wrapper{
    width: 70px;
    background: $dark-blue
}

.aside-menu-wrapper{
    background-color: $dark-blue;
}
.calendar-meeting button.close-btn {
    margin: 0;
    background: transparent;
    border: 0;
}

.side-logo img {
    position: relative;
    top: -4px;
    left: 4px;
}
.rbc-calendar .rbc-toolbar {
    position: relative;
}
.rbc-event-content {
    color: #000;
    font-weight: 600;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    
}
// Event Details page
.event-details{
    background-color: #fff;
    border-radius: 8px;
    padding: 15px;
    margin-bottom: 60px;
    .top-heading{
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ECE7E7;
        padding-bottom: 10px;
        h2{
            font-weight: bold;
            font-size: 18px;
        }
        .join-button{
            padding: 5px 14px;
            margin:0;
            background: $btn-primary;
            border: 1px solid $btn-primary;
            font-size: 13px;
            border-radius: 8px;
            color: #fff
           
        }
        .close-button{
            @extend .join-button;
            border: 1px solid #ff5858;
            background-color: #fff;
            color: #ff5858;
            margin-left: 15px;
            &:hover{
                opacity: .5;
            }
        }
    }
    .meeting-info{
        padding-bottom: 40px;
        .meeting-date-time{
            display:flex;
            align-items: center;
            margin-top: 20px;
            img{
                width: 20px;
                margin-right: 30px;
            }
        }
        h4{
            color: #0070C0;
            font-weight: bold;
            font-size: 17px;
        }
        .hide-onfo{
            align-items: center;
            margin-left: 51px;
            margin-top: 30px;
            display: flex;
            h4{
                font-size: 14px;
                margin-bottom: 0;
                margin-right: 8px;
            }
            img{
                width: 20px;
            }
        }
    }
    .middle-section{
        border-bottom: 1px solid #ECE7E7;
        padding-bottom: 40px;
        border-top: 1px solid #ECE7E7;
        padding-top: 30px;
        margin-left: 51px;
        .visit-type{
            font-size: 15px;
            font-weight: bold;
        }
        .join-button{
            padding: 4px 13px;
            margin: 0;
            background: $btn-primary;
            border: 1px solid $btn-primary;
            font-size: 13px;
            border-radius: 8px;
            color: #fff;
            font-size: 13px!important;
        }
        h2{
            font-size: 20px;
        }
        p{
            font-weight: 600;
        }
        a{
            text-decoration: underline;
            font-weight: 600;
        }
        .options{
            position: relative;
            a{
                font-weight: 100;
                margin:0 2px
            }
            img{
                width: 24px;
                margin-left: 5px;
                position: absolute;
                left: -56px;
                top: -1px;
            }
        }
    }
   
    .left-section{
        width: 75%;
    }
    .right-section{
        width: 25%;
        padding-left: 10px;
        border-left: 1px solid #ECE7E7;
        .participant{
            border-bottom:1px solid #ECE7E7 ;
            padding-bottom: 15px;
            margin-bottom: 15px;
            margin-right: 15px;
            img{
                margin-right: 10px;
            }
        }
        h3{
            font-weight: bold;
            padding-bottom: 15px;
            padding-top: 10px;
        }
    }
    .description{
        margin-left: 51px;
        margin-top: 5px;
    }
    .second-header{
        align-items: center;
        border-bottom:1px solid #ECE7E7 ;
        padding-bottom: 15px;
        padding-top: 15px;
        .link-Icon{
            width: 20px;
            margin-right: 30px;
            height: 20px;
        }
        .copy-icon{
            display: inline-block;
            width: 16px;
                    margin-left: 10px;
                    height: 18px;
        }
        p{
            cursor: pointer;
         
        }
        a{
            color: #262020;
           
        }
        .url-link-wrapper{
            display: flex;
        }
        .url-link{
            color: #0070C0;
            p{
                word-break: break-all;
            }
            &:hover{
                color: #0070C0;
                text-decoration: underline;
            }
        } 
    }
}
.see_more_btn{
    background-color: $btn-primary;
    color: #fff;
    border: none;
    border-radius: 6px;
    padding: 5px 14px;
    margin-left: 10px;
}
//Room
.room{
    position: relative;
    border-radius: 10px;

    .scroll-down{
        position: absolute;
        border: none;
        bottom: 86px;
        right: 10px;
        border-radius: 4px;
        padding: 6px 10px;
        background-color: #fff!important;
        display: none;
        z-index: 1;
     i{
        color: #21b3c4;
     }
     &:hover{
        opacity: .8;
        outline: none;
     }
     &:focus{
        outline: none;
        box-shadow: none;
     }
    }
    .btn-show{
      display: flex;
    }
    .chat-badge{
        width: 10px;
        height: 10px;
        background: #21b3c4;
        border-radius: 10px;
        position: absolute;
        top: -4px;
        right: 10px;
    }
    .chat-input-wrap{
        bottom: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        height: 100%;
        .btn-footer{
            display: flex;
            gap: 6px;
            padding: 0 10px 0 0 ;
            .btn-tooltip{
                top: 6px;
                width: 64px;
                left: -69px;
            }
            .btn-tooltip-next{
                @extend .btn-tooltip;
                right: -69px;

            }
        }
        #chat-box{
            max-height: 145px;
            height: 44px;
            cursor:auto;
        }
        .send-btn{
            border-radius: 100px;
            background-color: transparent;
            border: none;
            padding: 8px;
            right: 11px;
            bottom: 4px;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            .cls-1{
                fill: #fff;
            }
            &:hover{
            .cls-1{
                fill: #24b1c3;
                transition: ease-in-out .4s;
            }
            .fa-paper-plane{
                color: #24b1c3;
            }
        }
            img{
                width: 24px;
            }
            &:hover{
                opacity: .8;
            }
            i{
                color: #fff;
            }
        }
        textarea{
            width: 100%;
            background: #4a4848!important;
            padding: 10px 35px 10px 10px!important;
            color: #fff!important;
            border-top: 2px solid transparent!important;
            border-left: 2px solid transparent!important;
            border-right: 2px solid transparent!important;
            border-bottom: 2px solid #24b1c3!important;

            font-size: 12px!important;
            resize: none;
            &::placeholder{
                color: #fff!important;
            }
            &:focus{
                border: 2px solid #85bfff!important;
            }
        }
    }
.chat-head-icon{
    margin-right: 12px;
    font-size: 18px;
    color: #fff;
}
    .chats-wrap{
        height: calc(100vh - 173px);
        overflow: auto;
        padding: 8px;
        .infotext{
            background-color: #282828;
            color: #cacaca;
            display: inline-block;
            border-radius: 12px;
            padding: 1px 9px;
            font-size: 11px;
            margin: 11px 0;
        }
        .next-line-text{
            white-space:pre-line;
        }
        &::-webkit-scrollbar-thumb {
            background: #21b3c4; 
        }
        .chat-user{
            margin-top: 22px;
            &:first-child{
                margin-top: 0;
            }
        }
       .user-pic{
        display: inline-block;
        width: 40px;
        background: #fff;
        border-radius: 100px;
        text-align: center;
        padding: 10px;
        margin-right: 10px;
        font-weight: 600;
        margin-top: 10px;
       }
       .hide-profile{
        visibility: hidden!important;
       }
        .user-name{
            color: #f68722;
            font-size: 12px;
        }
        .chat-text-wrap{
            display: flex;
            align-items: start;
            .chat-time{
                color: #fff;
                font-size: 12px;
                margin-top: 4px;
            }
            .chat-text{
                background: #4a4848;
                display: inline-block;
                border-radius: 0 9px 9px 9px;
                padding: 5px 6px;
                color: #fff;
                margin-top: 8px;
                .seemore-btn{
                    display: block;
                    background: transparent;
                    outline: none;
                    border: none;
                    color: #111c1d;
                    text-decoration: underline;
                    font-size: 12px;
                    padding: 0;
                }
                span{
                    word-break: break-all;
                }
                .username-time{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap:14px
                }
                p{
                    margin: 0;
                    word-break: break-all;
                }
                .chat-time{
                    text-align: right;
                    font-size: 10px;
                    color: gray;
                    margin: 0;
                }
            }
        }
        .self-text{
            justify-content: flex-end;
            .chat-text{
                border-radius: 7px 7px 0 7px;
                margin-top: 5px;
                background-color:#22b2c4;
                span{
                    word-break: break-all;
                }
                .chat-time{
                    color: #212529;
                }
            }
        }
    }
    .w-auto{
        width: auto!important;
    }
    .right-content{
        display: flex;
        align-items: center;
        .MuiIconButton-root{
            padding: 0;
            margin-right: 4px;
            .MuiIconButton-label{
                position: relative;
                .badge-light{
                    color: #000000;
                    background-color: #21b3c4;
                    position: absolute;
                    top: 0px;
                    right: 7px;
                }
                .badge{
                    padding: 3px 5px;
                    font-size: 55%;
                    border-radius: 32px;
                }
            }
        }
        .makeStyles-hide-5{
            display: inline-block;
        }
        .icon{
            margin-right: 12px;
        }
        .chat-icon{
            width: 22px;
            margin:0 12px
        }
    }
    .profile-default{
        display:none;
    }
    .log-out-wrap{
        .middle-content {
            .logut-content{
                font-size: 16px;
                font-weight: 500;
                margin: 0;
            }
        }
    }
    .MuiPaper-root.MuiDialog-paper{
        width: 390px!important;
    }
    .main-wapper{
        height: calc(100vh - 95px);
        position: relative;
        
        .column{
            display: flex;
            height: 100%;
            .first_column{
                flex: 75% 1;
            }
            .second_column{
                background-color: #181818;
                flex: 9%;
                border-bottom-right-radius: 10px;
                overflow-y: auto;
                .participant_height{
                    flex: 1 40%;
                }
                .participant_list{
                    padding: 10px;
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    gap: 10px;
                    height: calc(100% - 160px);
                    overflow-y: auto;
                    border-bottom: 2px solid gray;
                    video{
                        width:100%;
                    }
                    .participant {
                        flex: 1 40%;
                        border-radius: 6px;
                        justify-content: space-between;
                        display: flex;
                        flex-direction: column;
                        h3{
                            font-weight: 100;
                            font-size: 12px;
                            white-space: nowrap;
                            overflow: hidden;
                            width: 60px;
                            text-overflow: ellipsis;
                            text-transform: capitalize;
                        }
                        .option-button-wrap{
                            background-color: #000;
                            border-bottom-right-radius: 6px;
                            border-bottom-left-radius: 6px;
                        }
                    }
                    .participant-row{
                        margin:5px;
                        .participant_list_pic{
                            display: flex;
                            background: #c5c5c5;
                            width: 70px;
                            border-radius: 100px;
                            height: 70px;
                            text-align: center;
                            align-items: center;
                            justify-content: center;
                            margin: 0 auto;
                            color: #000;
                            font-size: 16px;
                        }
                        .participant_list_name{
                            color: #fff;
                            margin: 0;
                            font-size: 12px;
                            margin-top: 10px;
                        }
                    }
                }
                .default-pic-wrap{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-top:35px;
                    margin-bottom: 10px;
                    .default-pic{
                        width: 60px;
                        border-radius: 100px;
                        height: 60px;
                        background-color: #c5c5c5;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-size: 20px;
                        font-weight: 500;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
    .horizantanl-line{
        border-top: 1px solid gray;
     }
    .video-top-header{
        background-color: #181818;
        padding: 10px 10px;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        box-shadow: -2px 5px 10px 1px rgba(0,0,0,0.25);
        -webkit-box-shadow: -2px 5px 10px 1px rgba(0,0,0,0.25);
        -moz-box-shadow: -2px 5px 10px 1px rgba(0,0,0,0.25);
        flex-wrap: wrap;
        .share-btn{
            width: 35px;
            background: transparent;
            border: none;
            margin: 0 8px;
            img{
                width: 100%;
                height: 19px;
            }
        }
        .timer-wrap{
            display: flex;
            gap: 8px;
            margin-right: 3px;
            align-items: center;
            .timer{
                width: 70px;
                span{
                    font-size: 14px;
                }
            }
            .icon{
                width: 18px;
            }
         }
        .tooltip-show{
            .Custom-tooltip{
                width: 80px;
                z-index: 9999;
            }
            .share-screen{
                width: 100px;
            }
            .show-member{
                width: 140px;
                left: -30px;
            }
            .chats{
                min-width: 69px;
                width: 100%;
                left: -10px;
                top: 25px;
            }
        }
        .study-name{
            color: #fff;
            font-size: 15px;
            margin: 0;
            flex: 1;
            margin-right: 10px;
        }
        
    }
    .default-pic{
        width: 100px;
        border-radius: 100px;
        height: 100px;
    }
    .join-button{
        padding: 6px 28px;
        margin: 0;
        background: $btn-primary;
        border: 1px solid $btn-primary;
        border-radius: 8px;
        color: #fff;
        font-size: 14px!important;
    }
    .leave-btn{
        background:#d15706;
        color: #fff;
        border: 1px solid #d15706;
        padding: 6px 20px 6px 10px;
        margin-left: 18px;
        &:hover{
            opacity: .8!important;
            background:#d15706!important;

        }
        img{
            margin-right: 8px;
            width: 24px;
        }
    }
    // Local /Self Video css
    .local-participant{
        position: absolute;
        bottom: 6px;
        right: 6px;
        z-index: 99;
        max-width: 35%;
        width: 200px;
        .minimize-window{
            color: #ffff;
            text-align: right;
            position: absolute;
            right: 4px;
            top: 2px;
            background: #000000ad;
            padding: 3px 5px;
            border-radius: 4px;
            z-index: 1;
            transition: ease-in-out .5s;
            cursor: pointer;
            .minimize-w-icon{
                width: 19px;
            }
        }
        .participant{
            width: 100%;
            background: #000;
            text-align: center;
            overflow: hidden;
            height: 140px;
            transition: ease-in-out .5s;

            h3{
                width: 80px;
                left: 5px;
                display: none;
            }
        }
        
        .default-pic-wrap{
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            margin-top: 10px;
            transition: ease-in-out .5s;
            .default-pic{
                width: 70px;
                border-radius: 100px;
                height: 70px;
                background-color: #c5c5c5;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 26px;
                font-weight: 500;
                text-transform: uppercase;
                transition: ease-in-out .5s;
            }
        }
        
        video{
            height: 100%;
        }
    }
    .minimize-wrap{
        .participant{
            height: 28px!important;
        }
        .profile-default, .default-pic-wrap, video{
            display: none;
            transition: ease-in-out .5s;
        }
    }
    .participant{
        width:100%;
        background: #2b2b2b;
      
            text-align: center;
            position: relative;
        h3{
            position: absolute;
            top: 9px;
            left: 20px;
            font-size: 14px;
            color: #fff;
            white-space: nowrap;
            overflow: hidden;
            width: 165px;
            text-overflow: ellipsis;
            text-transform:capitalize;
            background: #000000ad;
            border-radius: 5px;
            padding: 5px 25px 5px 12px;
            z-index: 2;
        }
       
       }
       .option-button{
        background-color: transparent;
        border: none;
        border-radius: 29px;
        padding: 10px;
    }
    .MuiDrawer-paper{
        top:65px;
        right: 0;
        position: absolute;
        background-color: #181818;
        height: calc(100vh - 160px);
        transform: translateX(0px) translateX(0px)!important;
        border-bottom-right-radius: 10px;
        z-index: 99;
        transition-timing-function: ease-in-out ;
        transition-duration: 0.5s;
        .close-icon{
            width: 13px;
            height: 13px;
        }
        .search-participant{
            padding: 20px 10px 15px;
            position: relative;
            .close-icon-btn{
                position: absolute;
                top: 26px;
                right: 20px;
                border: transparent;
                background: transparent;
                .close-icon{
                    width: 13px;
                    height: 13px;
                }
                

            }
            input{
                background-color: #2e3235;
                border-color: #2e3235;
                color: #fff;
                height: 34px;
                padding-right: 38px;
                &::placeholder{
                    color: #BFBFBF;
                }
            }
        }
        .MuiIconButton-root{
            color: #fff;
        }
        .participant-list{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px 15px;
            .mice-icon{
                img{
                    width: 16px;
                }
            }
            .participant-name{
                display: flex;
                flex: 1;
                align-items: center;
                img{
                    margin-right: 10px;
                    margin-right: 15px;
                    background:#BDBDBD;
                    border-radius: 100px;
                    padding: 5px;
                }
                h5{
                    margin-bottom: 0;
                    font-size: 13px;
                    font-weight: 100;
                    width: 115px;
                    text-overflow: ellipsis;
                    text-transform: capitalize;
                    white-space: nowrap;
                    overflow: hidden;
                }
                .no_paticipant_text{
                    width: auto;
                }
                .default-pic{
                    background-color: #c5c5c5;
                    width: 40px;
                    height: 40px;
                    font-weight:bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-transform: uppercase;
                    margin-right: 10px;
                    font-size: 15px;

                }
            }
        }
    }
    .last-participant{
        .participant{
            height: 50%;
        }
    }
    .six-participant{
        .participant{
            display: flex;
            flex: 1 30%;
        }
    }
    .seven-participant{
        .participant{
            display: flex;
            flex: 1 23%;
        }
    }
}
mark, .mark{
    background-color: #e7c235!important;
}
.video-icon{
    width: 15px;
    margin-left: 5px;
}
.blue-button{
    padding:6px 14px!important;
}
.remote-participants{
    display: flex;
    height: 100%;
    flex-wrap: wrap;
    background-color: #171717;
    gap: 5px;
    overflow: hidden;
    
   .one-participant{
       position: absolute;
       align-self: center;
       text-align: center;
    background: #2e3235;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .participant-width{
        width: 50%;
    }
       h1{
           color: #fff;
           margin-top: 20px;
           font-size: 18px;
           z-index: 1;
       }
       img{
        width: 120px;
        height: 120px;
        border-radius: 100px;
       }
   }
    .participant{
        display: flex;
         flex: 1 23%;
         height: 100%;
         overflow: hidden;
        align-items: center;
        justify-content: center;
        background-color: #2e3235;
        border-radius: 6px;
        
        .default-pic{
            width: 120px;
            height: 120px;
        }
        .option-button-wrap{
            position: absolute;
            top: 3px;
            left: 139px;
            margin-left: 10px;
            z-index: 2;
        }
        .option-button-wrap{
            .video-off{
                display: none;
            }
        }
    }
    .full-screen{
        flex: 100%!important;
        position: absolute;
        z-index:9
    }
    video{
        height: 100%;
        position: absolute;
        object-fit: contain;
        width: 100%;
        height: 100%;
    }
    .default-pic-wrap{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        margin-top: 20px;
        .default-pic{
            width: 150px;
            border-radius: 100px;
            height: 150px;
            background-color: #c5c5c5;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 42px;
            font-weight: 500;
            text-transform: uppercase;
        }
    }
    
}
.loading-msg{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
    margin-top: 15%;
    .spinner-info{
        width: 30px;
        height: 30px;
    }
}
// Join Video popup css here
.choose-option{
    border-radius: 10px;
    background-color: #fff;
    -webkit-box-shadow: 5px 5px 25px -3px rgba(0,0,0,0.22); 
    box-shadow: 5px 5px 25px -3px rgba(0,0,0,0.22); 
    padding: 20px !important;
    .button-wrapper{
        h3{
            font-size: 13px;
            margin-top: 10px;
            margin-top: 0;
        }
    }
    .custom-switch .custom-control-label{
        cursor: pointer;
        &::before{
            width: 2.75rem;
            height: 1.3rem;
            pointer-events: all;
            border-radius: 2.5rem;
        }
    }
    .custom-switch .custom-control-label::after{
        width: calc(1.2rem - 4px);
    height: calc(1.2rem - 4px);
    }
    .custom-switch .custom-control-input:checked ~ .custom-control-label::after{
        transform: translateX(1.50rem);
        background-color: #2b2b2b;
    }
    .custom-control-input:checked ~ .custom-control-label::before{
        background-color: #e7c235!important;
        border-color: #e7c235;
    }
    .custom-switch .custom-control-label::after{
        border: gray solid 2px;
        background-color: #ffffff00;
    }
    .custom-control-label::before{
        background-color: #ffffff00;
    border: gray solid 2px;
    box-shadow: none;
    &:focus{
        border: gray solid 2px;  
    }
    }
    .custom-switch .custom-control-label::after{
        top: calc(0.30rem + 2px);
    left: calc(-2.15rem + 2px);
    }
    h4{
        font-weight: bold;
        margin-bottom: 15px;
    }
    
    .join-btn{
        padding: 8px 16px;
        margin: 0px 0 0 0;
        background: $btn-primary;
        border: 1px solid $btn-primary;
        font-size: 13px;
        border-radius: 8px;
        color: #fff;
    }
    .cancel_button{
        border: 1px solid #FC4B42;
        background-color: transparent;
        margin-right: 20px;
        padding: 8px 16px;
        margin: 0px 20px 0 0;
        color: #FC4B42;
        border-radius: 8px;
    }
    .middle-view{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 350px;
        overflow: hidden;
    }
    .aduio_icon{
        margin-right: 15px;
        width: 20px;
        cursor: pointer;
    }
    .video-wrap{
        background-color: #2b2b2b;
        position: relative;
        height: 100%;
        border-radius: 4px;
        border: 1px solid #000;
            .middle-view{
                video{
                    width: 100%;
                }
            }
        .bottom-secton{
            border-top: 1px solid #000;
            position: absolute;
            bottom: 0;
            width: 100%;
            border-radius: 4px;
            padding: 10px 20px;
        }
        
    }
    .audio-option-wrap{
        .selction-audio-wrap{
            padding: 20px 25px;
            
            .dropdown{
                .btn.btn-secondary.dropdown-toggle:after{
                    color: gray;
                    font-size: 12px;
                }
            }
            .dropdown-toggle{
                padding: 0;
                background: transparent;
                border: none;
                &:hover{
                    background-color: transparent!important;
                border: none;
                }
            }
            .volum-wrapper{
                margin-left:30px;
                flex: 1;
                align-items: center;
                img{
                    margin-right: 15px;
                }
            }
            .selction-audio {
                border-bottom: 1px solid gray;
                padding-bottom: 15px;
                margin-bottom: 15px;
            }
        }
        .audio-card{
            background-color: #2b2b2b;
            border:1px solid #000;
            border-radius:4px;
            margin-bottom: 4px;
            padding: 15px 25px;
            .text-gray{
                color: gray;
            }
            .left{
                flex: 1;
            }
        }
        .c-audio{
            background-color: #454545;
            border-bottom: 1px solid #000;
            padding: 20px 25px;
        }
        .check-circle{
            width: 18px;
            height: 18px;
            border-radius: 100px;
            border:2px solid gray
        }
    }
}

// Feedback page css here
.feedback-wrap{
    background-color: #fff;
    padding: 40px 0px 10px 0px;
    border-radius: 10px;
    width:550px;
    margin: 0 auto;
    .feedback_inner{
        .first-text{
            text-align:center;
            line-height: 1.5;
            font-size: 17px;
        }
        .second-text{
            text-align:center;
            line-height: 1.5;
            font-size: 17px;
        }
        .select-feedback{
            display: flex;
            align-items:center;
            justify-content: center;
            margin: 40px 0;
            .select-feedback-button{
                padding:15px 20px;
                -webkit-box-shadow: 0px 3px 8px 3px rgba(0,0,0,0.17); 
                box-shadow: 0px 3px 8px 3px rgba(0,0,0,0.17);
                border-radius: 8px;
                margin-right: 40px;
                border: none;
                background-color: #fff;
                border: 2px solid transparent;
                &:last-child{
                    margin-right: 0;
                }
                img{
                    width: 60px;
                }
            }
            .active{
                border: 2px solid #E7C235;
            }
        }
        .message-box{
            .error-msg{
                color: red;
            }
            textarea{
                border: 1px solid #B0B0B0;
            }
            .label-text{
                font-size: 1rem;
                text-align: center;
            }
            .button-wrap{
                text-align: center;
                margin-top: 25px;
                .submit-btn{
                    border-radius: 8px;
                    background-color: #0070c0;
                    color: #fff;
                    box-shadow: none;
                    border: none;
                    padding: 10px 25px;
                }
            }
        }
    }
    

}
.invite-information, .calendar-reminder, .log-out-wrap{
    z-index: 99999!important;
}

// event reminder popup
.event-reminder{
    .MuiBackdrop-root{
        display: none!important;
    }
    .MuiDialog-scrollPaper{
        display: flex;
        align-items: flex-start;
        justify-content: flex-end;
        .MuiPaper-root.MuiDialog-paper {
            width: 400px!important;
        }
    }
    .join-button{
        padding: 5px 20px;
        margin:0;
        background: $btn-primary;
        border: 1px solid $btn-primary;
        font-size: 13px;
        border-radius: 8px;
        color: #fff
       
    }
    .close-button{
        @extend .join-button;
        padding: 5px 13px;
        border: 1px solid #ff5858;
        background-color: #fff;
        color: #ff5858;
        margin-right: 15px;
    }
    .reminder-btn-wrap{
        justify-content: flex-end;
        align-items: center;
        display: flex;
        gap: 15;
        padding-bottom: 10px;
        padding-right: 25px;
    }
    .middle-content{
        padding: 15px 15px 0 15px!important;
        .top-header{
            margin-bottom: 8px;
            .top-header-contet{
                display: flex;
                gap: 10px;
                align-items: center ;
                justify-content: space-between;
                width: 100%;
            }
            h4{
                font-weight: bold;
            }
        }
        .details-icon{
            width: 17px;
            cursor: pointer;
        }
        .reminder-icon{
            width: 22px;
            height: 22px;
        }
        .event-details-text{
            padding:0px 0 0 10px;
            .color-text{
                color: #1CB400;
            }
            .text{
                font-size: 14px;
            }
        }
        .event-details{
            margin-bottom: 0;
            padding:10px 0 10px 10px;
            margin-bottom: 5px;
            border-bottom: 1px solid rgb(194, 194, 194);
            border-radius: 0;
            &:last-child{
                border-bottom: none;
            }
            
            .event-name{
                font-size: 14px;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .time-wrap{
                display: flex;
                justify-content: space-between;
                align-items: center;
                .clock-icon{
                    width: 20px;
                    margin-right: 10px;
                }
                .event-time{
                    font-weight: bold;
                    font-size: 15px;
                }
            }
            .event-content{
                gap: 10px;
                .No-event-icon{
                    width: 20px;
                }
            }
        }
    }
    
}
.invite-information{
     .MuiDialog-paperWidthSm{
        .middle-content{
            width: 100%!important;
            .site-info{
                height: 360px;
                overflow-x: auto;
            }
        }
     }
}

 .filter-btn-wrap{
     margin-right: 10px;
     button{
         background-color: $btn-primary!important;
         border-color: $btn-primary!important;
     }
 }
// thank u css here
.thank-you-wrap{
    width: 100%;
    margin: 90px auto;
    background-color: #fff;
    border-radius: 8px;
    text-align: center;
    h2{
        font-weight: bold;
        margin: 20px;
    }
   
}
// Policies
html {
    scroll-behavior: smooth;
  }
.policy-page{
    
    a{
        transition: all 0.4s ease-in-out;
    }
    .footer{
        position: unset!important;
        width: 100%;
    }
    .container{
        max-width: 1340px;
        ul{
            li{
                font-size: 14px;
            }
        }
        .font-16{
            font-size: 16px;
        }
        .font-weight{
            font-weight: bold;
        }
        p{
            font-size: 14px;
            margin-top: 22px;
            line-height: 1.8;
        }
        h1{
            font-size: 32px;
        }
        .heading{
            font-weight: bold;
            margin-top: 24px;
            margin-bottom: 14px;
        }
    }
    .policies-header{
        box-shadow: 1px 1px 8px 1px rgb(0 0 0 / 15%);
        .policies-header-logo{
            width: 326px;
            margin-left: 14px;
        }
    }
}
.expand-video{
    position: absolute;
    top: 12px;
    right: 10px;
    background-color: #000000ad;
    padding: 3px;
    border-radius: 4px;
    cursor: pointer;
    .zoom-tooltip{
        z-index: 99;
        width: 65px;
        left:-33px;
    }
    .expand-video-icon{
        width: 27px;
        filter: brightness(0) invert(1);

    }
}
body{
    .meeting-wrap{
        padding:15px 0!important
    }
}
// error login page css here
.error-files{
    .error-files_content{
        cursor: pointer;
        &:hover{
            p , .date_time {
                color: $link-color!important;
                transition: ease-in-out;
            }
        }
    }
}
.error-page_sppiner{
    top: 45%;
    left:50%;
    position: absolute;
}
.error-log{
    .error-log_header{
        justify-content: unset!important;
    }
    .error-log_back-btn{
        margin-bottom: 0!important;
        margin-right: 5px;
        margin-left: 10px;
        &:hover{
            svg{
                fill: $btn-primary
            }
        }
        svg{
            fill: #fff;
            width: 20px;
        }
    }
    .error-date-wrap{
        display: flex;
        justify-content: flex-end;
        margin-bottom: 10px;
        .error-page_date{
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 11px 13px 0px 13px;
            .form-control{
                border: 1px solid #ccc;
            }
        }
    }
  
    
}
.error-log-height{
    height: calc(100vh - 147px);
    .error-log-file_content{
        // height: calc(100vh -240px)!important;
        height: 86%!important;
    }
}
@media (max-width: 1300px) {
    .my-notifiation {
        .my-notification-inner{
            .dash-noti-content{
                .noti-content-inner {
                    .content-detail{
                        width: 100%;
                    }
                }
            }
            
        }
    }
        .dash-noti-content{
            .noti-content-inner {
                .content-detail{
                    width: 100%;
                }
                .content-date{
                    display: flex;
                    width: 100%!important;
                    p{
                        margin-bottom: 10px;
                        margin-top: 10px;
                    }
                    &::before ,&::after{
                        display: none;
                    }
                   
                    .notification_time{
                        position: inherit;
                        top: 0;
                        left: 10px;
                    }
                }
            }
          
        }
}
@media (max-width: 992px) {
    .topbar-info{
        .topbar-item{
            .header-user{
                display: inline-block!important;
            }
        }
    }
    .ctms-video-conf {
        #LoginWrapper {
            .room {
                .main-wapper,.MuiDrawer-paper{
                    height: calc(100vh - 91px) !important;
                }
            }
        }
    }
    .custom-calendar .date_picker{
        left: 44%;
    }
    .dash-noti{
        margin-bottom: 25px;
    }
    .dash-reminder{
        margin-bottom: 65px;
    }
    .subheader-mobile{
        z-index: 1;
    }
    .event-details {
        margin-bottom: 90px;
        .main-wrapper{
            flex-direction: column;
            .left-section{
                width: 100%;
            }
        }
        .meeting-info {
            padding-bottom: 20px;
            .meeting-date-time img{
                margin-right:15px;
            }
        }
        .second-header {
            .link-Icon{
                margin-right: 15px;
            }
        }
        .middle-section{
            margin-right: 14px;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-left: 36px;
            h2{
                font-size: 14px;
            }
            .options img{
                left: -42px;
            }
        }
        .right-section{
            width: 100%;
            border: none;
            padding-left: 30px;
            h3{
                font-size: 14px;
            }
        }
        
        .top-heading {
            h2{
                font-size: 14px;
            }
        }   
     }
     .drawer-height .MuiDrawer-paper{
        height: calc(100vh - 170px) !important;
    }
     .room{
        margin-top: 48px;
        .main-wapper{
            height: calc(100vh - 170px)!important;
        }
        .MuiDrawer-paper{
            top: inherit;
        }
        .remote-participants{
            .one-participant{
                h1{
                    font-size: 16px;
                }
            }
            .default-pic-wrap {
                .default-pic{
                    width: 100px;
                    height: 100px;
                    font-size: 30px;
                }
            }
            video{
                width: 100%;
            }
        }
        
         .video-top-header{
             .study-name{
                 font-size: 13px;
                 font-weight: normal;
                 margin-bottom: 10px;
                 flex: 100%;
             }
            
         }
     }
     .topbar-search {
        .search-wrap {
            
            .close-icon-btn{
                top: 9px;
            }
            .search-box{
                font-size: 12px;
                &::placeholder{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
     }
    
     .my-account-wrapper{
         margin-bottom: 90px;
     }
     .log-out-wrap{
        .middle-content{
            margin: 0!important;
            border-top: none;
        }
        button,a{
            height: 40px;
            width: 70px;
        }
     }
     .notification-dropdown{
        box-shadow: -1px 6px 10px 3px rgba(0,0,0,0.18);
        -webkit-box-shadow: -1px 6px 10px 3px rgba(0,0,0,0.18);
        -moz-box-shadow: -1px 6px 10px 3px rgba(0,0,0,0.18);
        height:500px;
        .notify-inner.no-notications img{
            margin: 80px auto 5px auto !important;
        }
       
     }
     .stipend-filter{
         .right-section{
            .start-date-input, .end-date-input{
                width: 100%;
            }
         }
     }
    
}
@media (max-width:772px){
    .subheader-mobile .topbar #kt_quick_search_toggle{
        width: 35%;
    }

}
@media (max-width:685px) {
    .policy-page {
        .policies-header{
            text-align: center;
            .policies-header-logo{
                margin-left: 0;
            }
        }
    }
    .copyright-footer-wrap{
        gap: 10px;
        justify-content: center;
        flex-wrap: wrap;
    }


    .my-stipend-wrapper{
        margin: 0 14px 0 10px!important;
    }
    .my-document{
        margin-bottom:50px !important;
        .stipend-filter .select-box{
            width: 100%;
            margin-right: 0;
            margin-bottom: 10px;
        } 
       .tab-wrapper {
        .table-main-wrap{
            td{
                .dropdown-menu{
                    font-size: 12px!important;
                    width: 47vw;
                    .dropdown-item{
                        align-items: center;
                        img{
                            width: 24px;
                        }
                    }
                }
            }
        }
       }
    }
    .view-file-popup{
        .popup-heading {
            .popup-buttons{
                position: inherit;
                width: 32%;
                transform: none;
                display: flex;
                justify-content: flex-end;
                i{
                    font-size: 15px;
                    margin-top: 6px;
                }
            }
            h6{
                display: flex;
                flex-wrap: wrap;
                h6{

                    font-size: 13px;
                    width: 66%;
                    padding: 5px 4px;
                    word-break: break-all;
                }
            }
        }
        
    }
    .Date-button{
        width: 100%;
    }
    
    .room{
        .remote-participants{
            h3{
                width: 100px;
                font-size: 12px;
                padding: 5px 25px 5px 6px;
            }
            .option-button-wrap{
                left:78px;
                top: 1px;
            }
        }
        .mobile-view{
            .participant{
                flex: 1 1 46%;
                height: 50%;
                h3{
                    width: 100px;
                    font-size: 12px;
                    padding: 5px 25px 5px 6px;
                }
                .option-button-wrap{
                    left:78px;
                    top: 1px;
                }
            }
        }
        .local-participant{
            width: 45%;
            .default-pic-wrap{
                .default-pic{
                    font-size: 20px;
                }
            }
        }
    }
    .stipend-filter .right-section{
        flex-wrap: wrap;
        .select-box input[type=date]{ 
            flex: 1;
        }
        .start-date-input,
        .filter-btn-wrap{
            margin-right: 10px;
        }
        .start-date-input, .end-date-input{
            flex: 1;
        }
    }
    .my-stipend-tabs{
        height: calc(100vh - 350px);
        margin-bottom: 76px;
    }
    .site-info-mailbox{
        display: flex;
        margin-bottom: 10px;
        img{
            width: 16px;
            height: 16px;
        }
        span{
            word-spacing: normal;
            word-break: break-all;
        }
    }
    .middle-content{
        margin:10px 0 95px 0!important;
        .site-info-wrap .site-info-title .site-info-mail{
            margin-left: 15px;
    }
    }
    .Event-reminder-wrap{
        margin:10px 0 15px 0!important;
    }
    .MuiDialog-paper .video-logout{
        margin: 10px 0 0px 0 !important;
        border-top: 0;
    }
    .video-logout{
        .cancel-button, .logout-btn{
            width: 80px;
        }
    }
    body .content{
        padding: 0!important;
    }
    .subheader-mobile{
        margin: 0 14px 10px!important;
        z-index: 1;
    }
    .topbar-search .search-wrap .search-icon-btn{
        height: 96%;
    }
    .topbar-search .search-wrap .search-box{
        white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
    }
    .global-search {
        .quick-event {
            display: block;
            .study-Details_button{
                justify-content: flex-end;
            }
            .study-Details{
                .Right-side{
                    flex: 2;
                    .status{
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }
    .invite-information{
        .middle-content{
            .site-info{
                .site-info-right{
                    padding-left: 10px;
                    width: calc(100% - 105px);
                    .site-info-mailbox{
                        padding-right: 6px;
                        margin-right: 0px;
                    }
                }
                .site-info-left{
                    width: 100px;
                }
            }
        }
    }
 
.event-details{
    .top-heading{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        h2{
            margin: 0;
        }
    }
}
.dash-event {
    .dash-event-inner {
        .event-info {
            .error-msg{
                font-size: 13px;
            }
        }
        .event-img{
            img{
                width: 35px;
            }
        }
    }
}
.dash-noti{
    height: calc(100vh - 350px);
    .dash-noti-content {
        .noti-content-inner {
            .content-detail {
                display: flex;
                flex-wrap: wrap;
                .date-content-info{
                    flex: 80%;
                }
                .content-time{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    justify-content: flex-end;
                    width: 100%;
                    p{
                        margin: 0;
                    }
                }
                .content-detail-img{
                    margin-left: 0;
                }
            }
        }
    }
}
.my-notifiation{
    .content-detail {
        display: flex;
        flex-wrap: wrap;
        .date-content-info{
            flex: 80%;
        }
        .content-time{
            display: flex;
            align-items: center;
            gap: 15px;
            justify-content: flex-end;
            width: 100%;
            p{
                margin: 0;
            }
        }
        .content-detail-img{
            margin-left: 0!important;
        }
    }
}          
.dash-top {
    .dash-top-right{
        text-align: right;
        button.red-button{
            padding: 6px 14px;
        }
    }
    .dash-top-left {
        span{
            font-size: 14px;
        }
        p{
            font-size: 13px;
        }
    }
}
.dash-reminder{
    height: calc(100vh - 350px);
}
    //select-box css
    .MuiMenu-list{
        li{
            p{
                display: flex;
                flex-wrap: wrap;
            }
        }
    }
    .rbc-calendar {
        .rbc-header span{
            font-size: 12px;
        }
        .rbc-toolbar {
            span.rbc-toolbar-label{
                font-size: 14px;
                margin-right: 50px;
            }
            button{
                font-size:12px;
            }
        }
    }
    .topbar-search {
        .search-wrap {
            .search-box{
                font-size: 13px!important;
                &::placeholder{
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
     }
     .error-log-height {
        height: auto;
        .error-log-file_content{
         height: auto!important;
        }
     }
}
@media (max-width: 610px) {
    .tooltip-show{
        .Custom-tooltip{
            display: none!important;
        }
    }
    .background-white{
        background-color: transparent!important;
    }
     .notification-form-wrap{
        .notification-form{
          margin: 0 10px;
          display: flex;
          height: 100%;
          width: 85%;
          background-color: #fff;
        }
        .notify-see{
          width: 85%;
          margin: 0 10px;
  
        }
    }
   .global-search{
       .visit-form{
        .form-row{
            padding: 12px;
            h4{
                font-size: 14px;
            }
        }
        .user-details{
            gap: 10px;
        }
        .form-row {
            .form-icon{
                width: 22px;
                margin-right: 10px;
            }
        }
       
       }
   }
    
}
@media (max-height: 510px) {
    
    .side-logo, .side-logo1{
        position: relative !important;
    }
    .my-document{
        .Date-button{
            width: 100%;
            display: flex;
        }
    }
   

  }

  .date-content-info b, .notify-content b {
    cursor: pointer;
}
i.green-more{
    color: #47A400 !important;
}

div#kt_quick_notifications_toggle {
    position: relative;
}

.help-support-inner{
    padding: 10px;
    flex-grow: 1;
    border-radius: 10px;
    background-color: #fff;
    height: calc(100vh - 40px);
    margin-bottom: 40px;
}
.my-stipend-tab-inner .table-main-wrap {
    .payment-date{
        width: 160px;
    }
    .reference{
        @extend .payment-date
    }
    .amount{
        width: 160px;
    }
}
.select-box {
    margin-right: 10px;
    .start-date-input{
        margin-right: 10px; 
    }
    input[type="date"]{
        height: 33px;
        font-size: 13px;
        border: 1px solid #CECECE;
        border-radius: 8px;
        padding-left: 10px;
        -webkit-appearance: textfield;
        -moz-appearance: textfield;
    line-height:normal!important;
   
    }
}

.stipend-filter button {
    height: 33px;
    font-size: 13px !important;
    line-height: 17px !important;
    border-radius: 8px !important;
    padding: 5px 42px;
    width: 100%;
    float: left;
}
.my-document.help-support .table-main-wrap {
    height: calc(100vh - 230px) !important;
}
.helpsidebar .MuiPaper-root.MuiDialog-paper {
    max-width: 400px;
    right: 0 !important;
    margin: 0 !important;
    height: 100%;
    max-height: 100%;
    border-radius: 0;
    float: right;
}
.helpsidebar .MuiDialog-scrollPaper {
    display: block;
}
.helpsidebar-inner p {
    text-align: left;
    font-size: 14px;
    color: #34434E;
}
.helpsidebar-inner p span {
    position: relative;
    padding-left: 15px;
}
.helpsidebar-inner p span:before {
    content: '';
    position: absolute;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #34434E;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
}
.helpsidebar-inner h4 {
    font-size: 16px;
    text-align: left;
    font-weight: 600;
    color: #34434E;
}
.helpbox {
    width: 100%;
    float: left;
    margin-bottom: 30px;
    text-align: left;
}
.helpbox img, .help-comment img {
    max-width: 47px;
    border-radius: 50%;
    max-height: 47px;
    margin-right: 15px;
    float: left;
}
.helpbox .helpbox-inner, .help-comment .help-comment-inner {
    width: calc(100% - 62px);
    float: left;
}
.helpbox .helpbox-inner textarea {
    width: 100%;
    float: left;
    max-height: 124px;
    min-height: 124px;
    border: 1px solid #D2D2D2;
    border-radius: 8px;
    margin-bottom: 12px;
    padding: 10px;
}
.helpbox-inner .close-btn {
    border: 1px solid #f1d1cf !important;
    background: transparent !important;
    color: #FC4B42 !important;
}
.help-comment {
    width: 100%;
    float: left;
    margin-bottom: 40px;
    text-align: left;
}
.help-comment .help-comment-inner h6 {
    font-weight: 600;
    font-size: 13px;
}
.help-comment .help-comment-inner span {
    padding-left: 5px;
    font-weight: 100;
    font-size: 12px;
}
.help-comment .help-comment-inner p {
    font-size: 13px;
}
.my-account-wrapper.personal-account .progress {
    margin: 0;
}
.no-events {
    border: 1px solid $link-color;
    width: 100%;
    float: left;
    border-radius: 8px;
    padding: 20px;
    text-align: center;
    margin-bottom: 20px;
    text-transform: capitalize;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    align-items: center;
   .No-event-icon{
       width: 35px;
       height: 35px;
       margin-bottom: 10px;
   }
}
.help-dropdown {
    width: 100%;
    float: left;
    padding: 20px 20px 0 20px;
    
}
.site_name{
    margin-bottom: 0;
}
.help-dropdown .select-dropdown {
    width: 49%;
    margin-right: 2% !important;
}
.help-dropdown .select-dropdown:nth-child(2){
    margin-right: 0 !important;
}
.helpbox-inner-inner {
    position: relative;
    width: 100%;
    float: left;
}
.helpbox-inner-inner .helpbox-attach {
    position: absolute;
    width: 100%;
    border: 1px solid #D2D2D2;
    bottom: 12px;
    padding: 10px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    font-weight: 600;
    cursor: pointer;
}
.helpbox-attach img {
    max-width: 15px;
    height: auto;
    max-height: inherit;
    border-radius: 0;
    margin-right: 5px;
    cursor: pointer;
}
.MuiFormControl-root.f-15 {
    width: 100%;
}
.form-margin{
    margin-bottom: 0;
}
.error-red{
    color:red;
    text-align: left;
    margin-top: 5px;
    font-size: 13px;
}
.error-center{
    text-align: center;
}
.help-dropdown .MuiFormControl-root {
    width: 48%;
    margin-right: 4%;
}
.help-dropdown .MuiFormControl-root:nth-child(2) {
    margin-right: 0;
}
.help-dropdown .MuiFormControl-root .MuiSelect-select:focus {
    background: transparent !important;
}

div#proxy-renderer{
    min-height: 400px;
}
.info-state{
    width: 100%;
    float: left;
    p {
        display: inline-block;
        margin-right: 10px;
        margin-bottom: 10px;
    }
}
.info-address{
    margin-bottom: 5px;
}
.pagination-wrap{
    p{
        font-size: 14px;
        font-weight:500;
    }
    .MuiTablePagination-input{
        border: 1px solid #999;
        border-radius: 4px;
    }
    .MuiTablePagination-select{
        font-size: 14px;
        display:flex;
        align-items: center;
    }
    .MuiSvgIcon-root{
        color: #000;
    }

}
div#kt_header_mobile {
    z-index: 1111;
}
.help-heading h3 {
    margin-bottom: 5px !important;
}
.help-heading h4{
    color: #000 !important;
    font-size: 13px;
}
.uplaod-profile h6 h4{
    color: #ffffff !important;
    font-size: 12px;
    margin: 0 !important;
}
.uplaod-profile h6 p{
    margin: 2px 0 0 0 !important;
}
.my-document .tab-wrapper .table-main-wrap td .dropdown-menu{
    background: #eaf7f9;
    padding: 10px;
    text-align: center;
    width: 15rem;
    word-break: break-word;
    white-space: normal;
    font-size: 13px ;
}
:focus{
    outline: 0;
}
.dominant-speaker {
    border: 4px solid #E7C235 !important;
}

.hide_participant{
    display: none !important;
}
textarea.form-control.form-control-solid.h-auto{
    border-bottom: 1px solid #CECECE;
    background: transparent;
    border-top: none;
    border-left: none;
    border-right: none;
    border-radius: 0;
    padding-left: 0px;
    font-size: 14px;
    cursor: auto;
}
#user-notification-tooltip{
    z-index: 9999!important;
}
.topbar-item {
    .header-user {
        display: grid!important;
        grid-template-columns: 42px 29px 100px;
        .symbol-label {
            display: flex!important;
            align-items: center;
            justify-content: center;
            text-transform: uppercase;
            color: #000;
            background-color:#d7dbdc;
            font-size: 14px!important;
    }
    }

}
.login-signin{
    .form{
        .margin-bottom-10{
            margin-bottom: 18px!important;
        }
    }
}
// My form css here

.select-box input::placeholder {
    line-height:normal!important;
    width: 180px;
  }
  
  .select-box input:focus::placeholder {
    color: gray;
    width: 100%;
  }
  
  .select-box input[type=date]:focus {
    background-color: #fff;
 
    line-height:normal!important;
  }
  .select-box  input[type=date]::-webkit-input-placeholder {
    line-height:normal!important;
}
.progress-bar {  // this is for the bar itself
    background-color: $dark-blue
  }
  .form-padding{
      padding: 0!important;
  }
  .full-width-footer{
    width: 100%;
    left: 0;
  }
  .finish-arrow{
      img{
          width: 19px;
      }
  }
  .tooltip { 
      z-index: 1 !important;
      opacity: 0; 
}
.feedback-main-wrap{
    .feedback-site-logo{
        width: 190px;
        margin: 0 auto;
        img{
            object-fit: contain;
            width: 100%;
        }
    }
}
.terms-wrap{
    .content-text{
        font-size: 16px!important;
        line-height: 30px!important;
    }
}
.terms-list{
    padding-left: 18px;
    font-size: 16px;
    line-height: 30px;
    li{
        margin-bottom: 20px;
        padding-left: 10px;

        span{
            font-size: 16px;
            font-weight: 900;
        }
        &::marker{
            font-weight: bold;
            font-size: 16px;
        }
    }
}
@media (max-width: 1254px) {
    .select-study-drop{
        width: 58%;
    }
    .my-account-wrapper{
        .borderLeft-md{
            border-left: 0!important;
        }
        .profile-wrapper{
            margin-bottom: 25px;
        }
    }
}
@media (max-width: 1200px) {
    .my-stipend-tab-inner{
       .stipend-filter{
        display: block!important;
        .right-section{
            position: relative;
            .validation-msg{
                flex: 1;
                font-size: 10px;
            }
        }
       }
       .left-text-wrapper{
           margin-bottom: 15px;
       }
    }
    
}
@media (max-width: 1022px) {
    .select-study-drop{
        width: 54%;
    }
    .topbar #kt_quick_search_toggle{
        width: 40%;
    }
}

@media (max-width: 845px) {
    .my-account-wrapper{
        .borderLeft-sm{
            border-left: 0!important;
        }
        .ph-0{
            padding:0
        }
    }
   
}
@media (max-width : 792px){
    .my-stipend-tab-inner{
        .stipend-filter{
         .right-section{
             position: relative;
             margin-bottom: 10px;
             .validation-msg{
                 position: absolute;
                 bottom: -20px;
                 left: 12%;
             }
         }
        }
        .left-text-wrapper{
            margin-bottom: 15px;
        }
     }
     .custom-calendar{
        height: calc(100vh - 216px);
        margin-bottom: 100px;
        .date_picker{
            left: 46%;
        }
     }
     .my-document {
        .document-inner{
            margin-bottom: 70px!important;
        }
        .my-stipend-tabs{
            margin-bottom: 65px !important;
        }
            .tab-wrapper {
                .MuiTabs-indicator{
                    background-color: transparent!important;
                }
            }
     }
     .my-notifiation{
        .my-notification-inner{
            margin-bottom: 60px;
        }
     }
     .feedback-wrap {
         width: 95%;
        .feedback_inner {
            .select-feedback {
                .select-feedback-button{
                    margin-right: 10px;
                    padding: 12px 18px;
                    img{
                        width: 40px;
                    }
                }
            }
        }
     }
     .my-notifiation{
        margin: 0 10px;
    }
    .dashboard-main-content{
        margin: 0 10px 20px 10px;
    }
    .pagination-wrap{
        .MuiTablePagination-actions{
            margin-left: 0;
        }
        .MuiTablePagination-selectRoot{
            margin-right: 15px;
        }
    }
    #menu-{
        z-index: 9999!important;
        
    }
}
@media (max-width: 672px) {
  .profile-card-wrap{
      flex-direction: column!important;
      .profile-left-card{
          width: 100%!important;
          .card-custom{
              height: auto!important;
              margin-bottom: 10px!important;
          }
      }
      .card.card-custom{
          height: auto!important;
      }
  }
  }
@media (max-width: 610px) {
    .select-study-drop {
        width: 96% !important;
        margin-bottom: 10px !important;
    }
    .topbar #kt_quick_search_toggle {
        width: 100% !important;
        margin: 0 !important;
    }
    .rbc-btn-group{
        margin-right: 10px;
        width: 100%;
        text-align: center;
    }
    .rbc-toolbar-label{
        margin: 10px 0;
    }
    .my-notifiation{
        margin: 0 10px 40px 10px;
    }
    .my-document{
        width: 96%;
        margin: 0 auto;
    }
    .global-search{
        .my-document{
            margin:0 0 25px!important;
        }
        .visit-form{
            margin-bottom: 80px!important;
        }
    }
    .site-info-wrap{
        .site-info-right{
            padding-left: 20px;
        }
    }
    .middle-content .site-info-wrap .site-info-content .site-logo{
        width: 250px;
        margin-bottom: 15px;
    }
    .choose-option{
        margin-bottom: 80px;
        .row{
            gap: 10px;
        }
    }
    .stipend-filter {
        .right-section {
            .select-box {
                gap: 10px!important;
                input[type=date]{
                    margin-right: 0;
                }
            }
        }
    }
    .dashboard-main-content{
        .dash-event{
            flex-wrap: wrap;
        }
    }
    .custom-calendar .date_picker{
        left: 53%;
        top: 53px;
        img{
            right: 0;
        }
    }
    #user-notification-tooltip{
        z-index: 9!important;
    }
    .ctms-video-conf{
        .ctms-video-conf{
            #LoginWrapper {
                .room {
                    .main-wapper,.MuiDrawer-paper{
                        height: calc(100vh - 106px) !important;
                    }
                    .MuiDrawer-paper{
                        top: 104px;
                    }
                }
            }
        }
    }
}
// @media (max-width: 412px){
//     .stipend-filter {
//         .right-section{
//             .select-box{
//                 .start-date-input{
//                     margin-right: 0;
//                     margin-bottom: 10px;
//                 }
//             }
//         }
//     }
// }
@media (max-width:580px){
 
  .ctms-video-conf #LoginWrapper {
    .room {
        .main-wapper, .MuiDrawer-paper{
            height: calc(100vh - 106px) !important;
        }
        .MuiDrawer-paper{
            top: inherit;
        }
    }
  }
}
@media (max-width:540px){
    .drawer-height .MuiDrawer-paper{
        height: calc(100vh - 185px) !important;
    }
   .room{
    .MuiDrawer-paper{
        top: inherit;
    }
    .main-wapper{
        height: calc(100vh - 185px)!important;
    }
   }
  
}
@media (max-width: 510px) {
    .ctms-video-conf {
        #LoginWrapper {
            .room {
                .main-wapper,.MuiDrawer-paper{
                    height: calc(100vh - 126px) !important;
                }
                .MuiDrawer-paper{
                    top: 126px;
                }
            }
        }
       }
       .room{
        .MuiDrawer-paper{
            top: inherit;
        }
       }
    .my-stipend-wrapper{
        width: 94%!important;
    }
    .global-search{
        .visit-form{
         
         .form-row {
             .form-icon{
                 display: none;
             }
         }
        
        }
    }
    .feedback-main-wrap{
        .feedback-site-logo{
            width: 160px;
            
        }
       
    }
}
@media (max-width: 371px){
    .custom-calendar .date_picker{
    top: 53px;
    }

    .dash-reminder{
        margin-bottom: 80px;
    }
    .feedback-wrap {
        .feedback_inner {
            .first-text,.second-text{
                font-size: 14px;
        }
    }
    
    }
    .feedback-main-wrap{
        .feedback-site-logo{
            width: 160px;
            
        }
        .top-logo{
            width: 152px;
        }
    }
   #menu- .MuiMenuItem-root{
        white-space: pre-wrap;
    }
}

@media not all and (min-resolution:.001dpcm)
{ @supports (-webkit-appearance:none) and (stroke-color:transparent) {
     input[type='date']::before {
        color: #000000;
        content: attr(placeholder);
     
}
}}

.border-color-admin{
    border: 4px solid red;
}
.header-fixed.subheader-fixed.subheader-enabled .form-padding{
    padding-top: 20px!important;
}
.border-red{
    border: 2px solidm red;
}