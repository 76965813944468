@import "../../init";

.audit-trail-wrap{
    background-color: #fff;
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    border-radius: 8px;
    position: relative;
    .audit-trail-heading{
        background-color: $dark-blue;
        border-radius: 6px 6px 0 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        select{
            border-radius: 6px;
            background-color: #f0f2f5;
            height: 35px;
            border: none;
            margin-right: 15px;
            padding: 0 15px;
        }
       
        h3{
            padding: 12px 15px;
            font-size: 16px;
            color: #fff;
            margin-bottom: 0;
        }
    }
    .middle-content{
        overflow: auto;
        // height: calc(100% - 115px);
        height: calc(100vh - 190px);
        overflow: auto;
        .content-row{
            box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.1);
            -webkit-box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.1);
            -moz-box-shadow: 2px 2px 10px 0px rgba(0,0,0,0.1);
            margin: 15px;
            border-radius: 6px;
            .inner_content{
                padding:14px 15px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                border-radius: 6px;
            }
           
           
            .date_time{
                flex: 3;
                color: gray;
                align-items: flex-end;
                align-self: flex-end;
                text-align: right;
            }
            .content-text{
                flex: 5;
                font-weight: bold;
            }
        }
    }
    .button-card{
        position: absolute;
        text-align: right;
        padding:10px 15px;
        right: 0;
        bottom: 0;
        width: 100%;
        background-color: #fff;
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        border-radius: 0 0 6px 6px;
        display: none;
    }
}
@media (max-width: 510px){
    .audit-trail-wrap{
        .middle-content{
            .inner_content{
                .content-text{
                    flex: 100%;
                    text-align: left;
                }
            }
        }
    }
}