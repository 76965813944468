@import "../../init";
.full_name {
	color: #fff;
	font-size: 16px;
	margin-left: 10px;
	word-wrap: break-word;
	word-break: break-all;
	flex: 1;
}
.sticky-nav-bar {
	position: sticky;
	top: 0;
	z-index: 999;
	#admin-tooltip,
	#user-notification-tooltip {
		z-index: 99 !important;
	}
}
.admin-footer {
	.footer {
		width: 100%;
		left: 0;
	}
}
.admin-view-wrap {
	margin-bottom: 60px;
	position: relative;
	.site-logo{
		width: 160px;
		img{
			width: 100%;
		}
	}
	.Custom-tooltip{
		font-size: 12px;
		color: #3f4254;
		position: absolute;
		display: none;	
		background-color: #fff;
		border-radius: 4px;
		top: 50px;
		left: 40px;
		padding: 10px;
		box-shadow: 3px 4px 22px -1px rgba(0,0,0,0.26);
-webkit-box-shadow: 3px 4px 22px -1px rgba(0,0,0,0.26);
-moz-box-shadow: 3px 4px 22px -1px rgba(0,0,0,0.26);
z-index: 99;
	}
	.account-wrap{
		&:hover .Custom-tooltip{
			display: block;
		}
		.dropdown-menu:hover .Custom-tooltip{
			display: none;
		}
	}
	#demobutton {
		display: none;
	}
	#clear_data {
		display: none;
	}
	.search-icon-btn {
		border: none;
		border-radius: 0 100px 100px 0;
		background-color: #d7dbdc;
		padding-right: 12px;
	}
	.no-data-found {
		width: 100%;
	}
	.dropdown-menu {
		width: 280px;
		transform: translate(-192px, 10px);
		padding-top: 0;
		.profile_text {
			.Text_profile {
				padding: 10px 14px;
				border-radius: 5px;
				background-color: #f0f2f5;
				margin-right: 10px;
			}
		}
	}
	.study_search {
		align-items: center;
		width: 60%;
		gap: 10px;
		.Label_Site {
			font-weight: bold;
		}
	}
	.nav_wrap {
		width: 100%;
		display: flex;
		width: 100%;
		justify-content: space-between;
		align-items: center;
	}
	.top_header {
		.search-box {
			margin-right: 12px;
			width: calc(100% - 159px);
			#search-addon {
				display: none;
			}
			.close-icon-btn {
				box-shadow: none;
				border: none;
				background-color: #f0f2f5 !important;
				padding-left: 10px;
				padding-right: 12px;
				.close-icon {
					width: 15px;
				}
			}
			.input-group {
				border-radius: 100px !important;
				flex-wrap: nowrap;
				input {
					border-radius: 100px 0 0 100px !important;
					border-bottom: 0 !important;
					background-color: #f0f2f5 !important;
					padding: 22px 0 22px 22px !important;
					text-overflow: ellipsis;
					&::placeholder {
						font-size: 12px;
					}
				}
				.input-group-text {
					background-color: #d7dbdc;
					border-radius: 0 100px 100px 0 !important;
					cursor: pointer;
					i {
						color: #fff;
					}
				}
			}
		}
	}
	.navbar-brand {
		width: 250px;
		img {
			width: 100%;
		}
	}
	.navbar-nav {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0;

		.study-dropdown {
			background-color: #f0f2f5;
			width: 370px;
			border-radius: 100px;
			padding: 7px 10px;
			a {
				color: #000;
				font-weight: bold;
			}
		}
	}

	.footer {
		background: #e7c235;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		left: 0;
		span {
			color: #fff;
		}
	}
	.study-dropdown-wrap {
		.study-label {
			margin-right: 10px;
			font-size: 15px;
		}
		.dropdown-toggle {
			position: relative;
			&::after {
				position: absolute;
				right: 15px;
				top: 15px;
			}
		}
	}
	.account-wrap {
		background-color: #f0f2f5;
		padding: 6px;
		border-radius: 6px;
		position: relative;
		.account-dropdown-wrap {
			padding: 1px;
		}
		.header-profile {
			width: 38px;
			height: 38px;
			background: #d7dbdc;
			border-radius: 100px;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-right: 5px;
			img {
				border-radius: 100px;
			}
		}
		.user_text {
			background-color: #d7dbdc;
			border-radius: 100px;
			font-size: 14px;
			text-transform: uppercase;
			padding: 6px;
			letter-spacing: 3px;
		}
		a {
			&::after {
				display: none;
			}
		}
		.symbol {
			display: flex;
			align-items: center;
			gap: 10px;
			width: 100%;
			symbol-label {
				flex: 1;
			}
			.dropdown-profile {
				img {
					border-radius: 8px;
				}
			}
		}
	}
	.studies-wrap {
		.Top_heading_accordian {
			padding: 10px;
			color: #fff;
			background-color: $dark-blue;
			font-weight: bold;
			border-radius: 6px 6px 0 0;
			font-size: 21px;
		}
		.accordion-wrapper {
			margin-bottom: 70px;
			border-radius: 6px;
			background-color: #fff;
			.dash-top {
				float: none;
			}
		}
		h4 {
			font-weight: bold;
		}
		#accordion {
			padding: 15px;
			margin: 10px;
			.card {
				margin-bottom: 16px;
				background-color: #eafdff;
				.collapsing {
					transition: none;
				}
				.accordian_text {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: 20px;
				}
			}
			.card-body {
				padding: 1.25rem;
				overflow: auto;
				// background-color: #d7d8da;
				background-color: #f2faff;
				.table-heading-wrap {
					display: flex;
					justify-content: space-between;
					align-items: center;
					h5 {
						font-weight: bold;
					}
				}
			}
			.input-group {
				width: auto;
				margin-bottom: 10px;
			}
			.search-box {
				input[type="search"] {
					border-radius: 4px !important;
					border: 1px solid #a6a6a6;
					width: 260px;
				}
				.input-group-text {
					border-radius: 0 4px 4px 0;
					background-color: #0070c0;
					cursor: pointer;
					i {
						color: #fff;
					}
				}
			}
			button[aria-expanded="true"] {
				.accordian-icon {
					img {
						transform: rotate(180deg);
						transition: ease-in-out .3s;
					}
				}
			}
			.accordian-btn {
				width: 100%;
				text-align: left;
				padding: 8px 12px;
				font-size: 14px;
				text-decoration: none;
				color: #000;
				background-color: transparent;
				display: flex;
				justify-content: space-between;
				align-items: center;
				&:hover {
					text-decoration: none;
				}
				.accordian-icon {
					position: absolute;
					right: 10px;
					background-color: $btn-primary;
					padding: 6px 17px;
					border-radius: 4px;
					top: 8px;
				}
			}
			.card-header {
				padding: 0px;
				background-color: transparent;
			}
			.badge-blue {
				background-color: #b0b0b0;
				margin-right: 80px;
				font-size: 18px;
				padding: 10px 16px;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 48px;
			}
			.info-table {
				.option-img-wrap {
					display: flex;
				}
				thead {
					background: $dark-blue;
					color: #fff;
					th {
						padding: 15px;
						position: relative;
						white-space: nowrap;
						.sort-icon {
							color: #fff;
							margin-left: 5px;
							font-size: 12px;
						}
					}
				}
				tbody {
					.enrollment-status {
						background: #f04949;
						padding: 5px;
						border-radius: 4px;
						color: #fff;
						width: 150px;
						display: inline-block;
						text-align: center;
					}
					.enrollment-status-gray {
						background-color: #c2c2c2;
					}

					.green-status {
						background-color: #00aa25;
						@extend .enrollment-status;
					}
					tr {
						&:hover {
							background-color: #bfe0f6;
						}
						td {
							padding: 14px;
						}
						&:nth-of-type(even) {
							background: #fff;
							&:hover {
								background-color: #bfe0f6;
							}
						}
					}
				}
				.option-img {
					margin-right: 15px;
					padding: 7px 10px;
					border-radius: 4px;
					cursor: pointer;
					img {
						width: 16px;
					}
				}
				.trash-icon {
					background: #f04949;
				}
				.go-to-dash {
					background: $btn-primary;
				}
			}
		}
	}
}
@media (max-width: 991px) {
	.admin-view-wrap {
		.badge-blue {
			font-size: 15px;
		}
		.studies-wrap {
			#accordion .card .accordian_text {
				font-size: 14px;
			}
			.Top_heading_accordian {
				font-size: 16px;
			}
			#accordion {
				padding: 2px;
			}
		}
		.navbar-brand {
			width: 198px;
			padding: 3px;
		}
		.nav_wrap {
			flex-wrap: wrap;
			.study_search {
				align-items: center;
				gap: 15px;
				.study-dropdown-wrap {
					margin-bottom: 10px;
					.study-dropdown {
						width: 320px;
					}
				}
			}
		}
	}
}
@media (max-width: 768px) {
	.admin-view-wrap {
		.nav_wrap {
			.study_search {
				order: 3;
				align-items: center;
			}
		}
	}
}
@media (max-width: 680px) {
	.admin-view-wrap {
		.info-table {
			display: block;
			width: 100%;
			overflow-x: auto;
		}
		.nav_wrap {
			.study_search {
				gap: 15px;
				width: 100%;
				.search-box {
					width: 100%;
				}
				.study-dropdown-wrap .study-dropdown {
					width: 290px;
				}
			}
		}
		.account-wrap {
			margin-bottom: 5px;
			padding: 8px 16px;
		}
	}
}
@media (max-width: 510px) {
	.admin-view-wrap {
		.nav_wrap {
			.study_search {
				order: 3;
				flex-wrap: wrap;
				align-items: center;
				.select-dropdown {
					width: calc(100% - 10px);
				}
			}
			.navbar-brand {
				width: 150px;
			}
		}
		.studies-wrap #accordion .search-box {
			input[type="search"] {
				width: auto;
			}
		}
		
	}
}
