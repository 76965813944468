@import "../../init";

.add-admin-wrap{
    background-color: #fff;
    box-shadow: 0px 0px 30px 0px rgb(82 63 105 / 5%);
    border-radius: 8px;
    .search-icon-btn{
        border: none;
    }
  .table_wrap{
    height: calc(100vh - 202px);
    overflow: auto;
  
  }
    .MuiTypography-h6{
        text-align: center;
    }
    .add-admin-head{
        background-color: $dark-blue;
        border-radius: 6px 6px 0px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 15px;
        .add-admin-btn{
            background-color: $btn-primary;
            border: none;
            color: #fff;
            border-radius: 4px;
            padding: 6px 14px;
            display: flex;
            align-items: center;
            .addsymbol{
                font-size: 15px;
                font-weight: bold;
                margin-right: 6px;
            }
        }
        h3{
            padding: 18px 15px;
            font-size: 16px;
            color: #fff;
            margin-bottom: 0;
        }
    }
    .sort-icon{
        font-size: 13px;
        margin-left: 10px;
    }
    .action-button{
        padding:8px 12px;
        border-radius: 6px;
        cursor: pointer;
        display: inline-block;
        width: 40px;
        text-align: center;
        img{
            width: 12px;
        }
    }
    .action-add-btn{
        background-color: $btn-primary;
        margin-right: 20px;
        img{
            width: 17px;
        }
    }
    .action-cancel-btn{
        background-color:#f04949;
    }
    thead{
        tr{
          th{
            padding:14px 12px
          }   
        }
    }
    tbody{
        tr{
            td{
                padding:14px 12px
            }
        }
    }
    .table-striped tbody tr:nth-of-type(odd){
        background-color: #f0f0f0;
    }
}
