
// Initialization of global variables, mixins and functions
@import "../../init";
    .city, .country ,.state{
        width: 124px !important;
        margin-right: 14px!important;
    }
    
    .login-logo{
        width: 300px;
    }
    .region-select-drop {
        position: relative;
        img{
            margin-right: 10px;
        }
        .css-26l3qy-menu{
           text-align: left;
        }
    }
    .region-popup{
        .MuiPaper-root.MuiDialog-paper{
            width: 450px!important;
        }
        .MuiDialog-paperScrollPaper,.MuiDialogContent-root{
            overflow: inherit!important;
        }
        .MuiIcon-root{
            overflow: inherit;
            font-size: 3rem;
            width: auto!important;
        }
    }
.login.login-1 {
    // Form modes
    .login-signin,
    .login-signup,
    .login-forgot {
        display: none;
    }

    &.login-signin-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: block;
            .contact-admin-text{
                margin: 0!important;
                
            }
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-signup-on {
        .login-signup {
            display: block;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: none;
        }
    }

    &.login-forgot-on {
        .login-signup {
            display: none;
        }

        .login-signin {
            display: none;
        }

        .login-forgot {
            display: block;
        }
    }
}
.login-root{
    .county-code{
        input{
            padding: 0 !important;
            border: none!important;
        }
    }
   
}
.Confim-popup{
    line-height: 1.5;
}
.user-name-wrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 27px;
    .user-name-text{
        font-size: 14px;
        .user-name{
            font-weight: bold;
        }
    }
    .fa-exclamation-triangle{
        font-size: 14px;
        margin-top: 10px;
    }
}
.error-message{
    font-size: 16px;
    color: red;
}
.login-wrapper {
    input[type="email"]{
        letter-spacing: 1px;
    }
    input, .css-1uccc91-singleValue, .css-1bpit5d-singleValue{
        padding: 10px!important;
        width: 100%!important;
        color: #B5B5C3;
        margin-left: 0;
        div{
            color: #333;
        }
    }
    .css-1wh03ml-singleValue{
        padding: 10px;
    }
    .css-b8ldur-Input{
        width: 100%;
        input{
            border-bottom: none!important;
            width: 100%!important;
        }
    }
    .login-signin-on {
        .alert-custom{
            float: unset;
            padding: 0.5rem 1rem;
            display: flex!important;
            justify-content: space-between;
            gap: 10px;
            .alert-text, .alert-close{
            float: unset;
            }
        }
        .login-left{
            flex: 50%;
        }
        .region-select-drop{
            margin-bottom: 15px;
            input{
                border-bottom: none!important;
            }
            img{
                margin-right: 10px;
                width: 30px;
            }
        }
    }
}
// Slider css here
.slider-wrapper{
    flex: 50%;
    .carousel{
        height: 100%;
    }
}
.carousel{
    flex: 50%;
    .carousel-control-prev,.carousel-control-next{
        display: none;
    }
    .carousel-indicators{
        .active{
            width: 30px;
            height: 3px;
        }
    }
    .carousel-item{
        position: relative;
        padding-bottom: 86.6%;
        height: 100%;
        img{
            position: absolute;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
    }
    .carousel-indicators{
        margin: 0 auto;
    }
    .carousel-inner{
        height: 100%;
    }
}
.left-section{
    .contact-icon {
        margin-right: 10px;
    }
}
// Video Congf
.ctms-video-conf{
    .copyright-footer{
        display: none;
    }
    .video-top-header{
        border-radius: 0!important;
    }
    #LoginWrapper{
        
        .login-left{
            padding: 0!important;
        }
        .login-left-top{
            display: block!important;
            width: 100%;
            margin-top: 0!important;
            .choose-option{
                max-width: 80%;
                margin-top: 80px;
            }
        }
        .flex-lg-row{
            flex-direction: column!important;
            background: transparent!important;
        }
        .room {
            .MuiDrawer-paper{
                height: calc(100vh - 65px);
            }
            .main-wapper{
                height: calc(100vh - 65px);
            }
        }
    }
    .copyright-footer-wrap{
        display: none;
    }
}
// Desktop view
@include media-breakpoint-up(lg) {
    .login.login-1 {
        .login-aside {
            width: 100%;
            max-width: 600px;
        }

        .login-form {
            width: 100%;
            max-width: 400px;
        }
    }
}

// Tablet and Mobile mode
@include media-breakpoint-between(lg, xl) {
    .login.login-1 {
        .login-aside {
            width: 100%;
            max-width: 400px;
        }
    }
}

// Tablet mode
@include media-breakpoint-down(md) {
    .login.login-1 {
        .login-form {
            width: 100%;
            max-width: 350px;
        }
    }
    .city, region-select-drop , .state{
        width: 100% !important;
        margin-right: 0 !important;
    }
    .carousel{
        display: none;
    }
}

// Mobile mode
@include media-breakpoint-down(sm) {
    .phone-no, .county-code{
        width: 100% !important;
        margin-right: 0 !important;
    }
    .ctms-video-conf{
        #LoginWrapper{
            .login-left-top{
                .choose-option{
                    max-width: 95%;
                }
            }
            
        }
    }
    
}
@include media-breakpoint-down(xs) {
    .login.login-1 {
        .login-form {
            width: 100%;
            max-width: 100%;
        }
    }
}
.login-root {
    width: 100%;
    float: left;
    background: #f7f7f7;
    height: 100%;
    padding: 30px 80px;
    overflow-y: auto;
    overflow-x: hidden;
}
@media (min-width: 1400px){
    .login-root {
        height: 100%;
        overflow-y: auto;
    }
    .login-wrapper{
        position: relative;
    }
    .login-wrapper-inner {
        height: 100%;
    }
}
@media (max-width: 1400px){
.login-wrapper-inner {
    height: 100%;
}
}
@media (max-width: 1200px){
    body .login-aside {
        min-width: 1px !important;
    }
}
@media (max-width: 992px){
    body .login-aside {
        display: none !important;
    }
    
}
@media (max-width: 510px){
    .first-name{
        width: 100% !important;
        margin-right: 0 !important;
    }
    body .login-wrapper .login-signin-on .login-left {
        padding: 15px !important;
    }
    .login-wrapper{
        padding: 32px 0 20px 0 !important;
    }
    p.copyright-footer{
        font-size: 12px !important;
    
    }
    .login-wrapper .login-signin-on {
        width: calc(100% - 60px) !important;
        .login-left-top {
            margin: 0 !important;
            p.patient-portal {
                font-size: 16px;
            }
        }

        .login-or{
            margin: 20px 0 !important;
        }
        
    } 
    form a#kt_login_forgot {
        font-size: 13px !important;
    }
    .login-signin img{
        max-width: 100%;
    }  
    body .login-left-footer {
        padding: 0 !important;
        margin: 0 !important;
        span, a{
            font-size: 13px !important;
        }
    }
    p.copyright-footer {
        font-size: 13px;
        padding: 0 12px;
    }
}
#root {
    display: block !important;
}
.social-icons {
    img {
        max-width: 40px;
    }
    button{
        border: none;
        background-color: transparent;
    }
}
.login-wrapper {
    background: $dark-blue !important;
    width: 100%;
    float: none;
    padding: 52px 0 20px 0;
    max-width: 1149px;
    margin: 0 auto;
    .login-signin-on {
        background: #fff !important;
        width: calc(100% - 104px);
        margin: 0 auto;
        .login-left {
            padding: 20px 60px 0 60px !important;
        }
    }
}

.login-signin {
    max-width: 100% !important;
    a{
        display: block;
        margin: 0px 0 40px 0 !important;
    }
    .alert-text{
        a{
            margin:0!important;
        }
    }
    .brand-login-logo img{
        max-height: 40px !important;
    }
        .patient-portal{
            margin: 0 !important;
    text-align: center;
    font-size: 16px;
    display: none;
    color: #494E56;
        }
        .login-or {
            position: relative;
            margin: 15px 0 10px 0 !important;
            font-size: 14px;
            color: #494E56 !important;
        }
        .login-or:before {
            content: '';
            position: absolute;
            background: #494E56;
            height: 2px;
            width: 34px;
            top: 8px;
            left: -62px;
            right: 0;
            margin: 0 auto;
        }
    
        .login-or:after {
            content: '';
            position: absolute;
            background: #494E56;
            height: 2px;
            width: 34px;
            top: 8px;
            right: -62px;
            left: 0;
            margin: 0 auto;
        }
        .login-or-main{
            margin: 0 !important;
        }
        
    }
    input::placeholder {
        color: #494E56;
        opacity: 1; /* Firefox */
      }
      
      input:-ms-input-placeholder { /* Internet Explorer 10-11 */
       color:#494E56;
      }
      
      input::-ms-input-placeholder { /* Microsoft Edge */
       color:#494E56;
      }
    form {
        input {
            background: transparent !important;
            border-top: 0 !important;
            border-left: 0 !important;
            border-right: 0 !important;
            border-bottom: 1px solid #CECECE !important;
            border-radius: 0 !important;
            padding: 0 0 10px 0 !important;
            font-size: 14px !important;
            color: #494E56 !important;
         
    }
    
    .form-group {
        margin-bottom: 20px !important;
    }
    button#kt_login_signin_submit {
        margin: 0 !important;
        width: 100% !important;
        background: $btn-primary !important;
        border: 1px solid $btn-primary;
        border-radius: 0;
        font-size: 14px;
        font-weight: normal;
        color: #262020;
        font-family: 'Poppins';
        span{
            color: #fff;
        }
    }
    a#kt_login_forgot {
        margin: 5px 0 !important;
        display: block;
        width: 100%;
        text-align: center;
        color: $link-color !important;
        font-size: 14px;
    }
}
.login-left-footer {
        text-align: center;
    span{
        display: inline-block;
        color: #494E56 !important;
        font-size: 13px;
        margin: 0 !important;
        font-weight: 200 !important;
    }
    a{
        display: inline-block;
        color: $link-color !important;
        font-size: 13px;
        margin: 0 !important; 
        padding: 0 2px !important;
    }
}
.login-aside{
    max-width: 50% !important;
}
body .login-left-footer {
    padding: 10px 0 20px 0 !important;
    margin: 0 !important;
}
p.copyright-footer {
    text-align: center;
    font-size: 14px;
}
.social-icons{
    text-align: center;
    a{
        margin: 0 0 0 15px !important;
        display: inline-block !important;
    }
}
.first-name {
    width: 48%;
    float: left;
    margin-right: 4%;
}
.last-name{
    margin-right: 0 !important;
}
form select {
    background: transparent !important;
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
    border-bottom: 1px solid #CECECE !important;
    border-radius: 0 !important;
    padding: 0 0 10px 0 !important;
    font-size: 14px !important;
    color: #494E56 !important;
}
.county-code {
    width: 100%;
    float: left;
}
.phone-no {
    width: 100%;
    float: left;
}
.user-form-details{
    .css-g1d714-ValueContainer{
        text-align: left;
    }
    .submit-btn{
        padding: 12px;
        width: 100%!important;
    }
    .user-details-btn{
        display: flex;
        .no-btn{
            width: 100%;
        }
    }
}
.register-button{
    margin: 0 !important;
    width: 100% !important;
    background: $btn-primary !important;
    border: 1px solid $btn-primary !important;
    border-radius: 0;
    font-size: 16px;
    font-weight: normal;
    color: #262020;
    font-family: "Poppins";
    border-radius: 0!important;
}
button#kt_login_forgot_submit {
    margin: 0 0 15px 0 !important;
    width: 100% !important;
    background: $btn-primary !important;
    border: 1px solid $btn-primary;
    border-radius: 0;
    font-size: 16px;
    font-weight: normal;
    color: #fff;
    font-family: "Poppins";
}
.login-forgot .font-size-h1{
    font-size: 20px !important;
}
.forgot-cancel{
    display: block;
    width:100%;
    button#kt_login_forgot_cancel {
        margin: 0 !important;
        width: 100% !important;
        background: #ffffff !important;
        border: 1px solid $btn-primary;
        border-radius: 0;
        font-size: 16px;
        font-weight: normal;
        color: $btn-primary;
        font-family: "Poppins";
    }
}
#search-panel-tooltip{
    background-color: transparent!important;
    border: none!important;
}
.login-signin {
    img{
        max-width: 100%;
    }
    .date-picker-reg{
        clear: both;
        position: relative;
        input{
         padding-left: 53%!important;
        }
        .placeholder-color{
            color: #b5b5c3!important;
        }
        label{
            position: absolute;
            top: 12px;
            left: 10px;
            margin: 0;
            font-size: 14px;
         color: #b5b5c3;

        }
    }
    
}