//
// Aside
//
#kt_aside_menu{
	height: 100%!important;
}
.aside-left{
	.main-logo{
		z-index: 99999;
	}
}
.aside {
	width: get($aside-config, base, width);

	// Aside Menu
	.aside-menu {
		@include perfect-scrollbar-ver-offset(4px);
	}
}
#kt_aside_menu{
	position: relative;
	.set-position{
		position: absolute;
		bottom: 0;
		width: 100%;
	}
}
ul.menu-nav {
	li a.active{
		padding: 0 !important;
		min-height: 1px !important;
		color:$link-color !important;
	}
	li a{
		color:#fff;
		font-size:15px !important;
	}
	li a span{
		color:#fff !important;
		font-size: 15px !important;
		flex: none !important;
	}
	li a span.svg-icon{
		margin-right: 10px;
	}
	.svg-icon svg g [fill]{
		fill:#fff !important;
	}
	a.active svg g [fill] {
		fill: $link-color !important;
	}
	li a.active span{
		color: $link-color !important;
	}
	li{
		padding: 17px 0 17px 28px !important;
    border-bottom: 1px solid #fff;
	}
}
.main-logo {
    background: #fff !important;
	width: 265px;
	z-index: 99999;
	position: relative;
}
.aside-menu {
    margin-top: 0 !important;
}
button#kt_aside_toggle {
    left: 80px !important;
    position: relative;
}

.aside-minimize-hover .brand-toggle, .aside-minimize-hover button#kt_aside_toggle.active{
	left: 80px !important;
}
button#kt_aside_toggle.active svg g [fill] {
    fill: $link-color !important;
}
.aside-minimize:not(.aside-minimize-hover) li a span:last-child {
    display: none;
}
.aside-menu {
	.menu-nav {
		 .menu-item  {
			.menu-link{
				padding: 0!important;
				min-height: auto!important;
			}
		 }
	}
}
// Desktop Mode
@include media-breakpoint-up(lg) {
	.aside {
		// Fixed Aside Mode
		.aside-fixed & {
			position: fixed;
			top: 0;
			bottom: 0;
			left: 0;
			z-index: 9999;
		}

		// Static Aside Mode
		.aside-static & {
			position: relative;
			z-index: 1;
		}

		// Fixed Header & Static Aside Modes
		.header-fixed.aside-static & {
			padding-top: get($header-config, desktop, default, height);
		}

		// Minimized Aside Mode
		.aside-minimize:not(.aside-minimize-hover) & {
			width: get($aside-config, base, minimized-width);
		}

		// Scrollbar Width
		@include perfect-scrollbar-ver-size(get($aside-config, base, scrollbar-width));
	}

	// Fixed Aside & Minimize Aside Hover Modes
	.aside-fixed.aside-minimize.aside-minimize-hover {
		.aside {
			width: get($aside-config, base, width);
			
		}

		.wrapper {
			padding-left: get($aside-config, base, minimized-width);
		}
	}


}

// Build Aside Menu
@include menu-ver-build-layout($aside-menu-config);

// Aside Offcanvas Panel For Mobile Mode
@include offcanvas-build(aside, tablet-and-mobile, get($aside-config, offcanvas-mobile));
@include media-breakpoint-up(lg) {
	button#kt_aside_toggle{
		margin-right: 30px;
	}
}
@media (max-width: 1000px) {
	.aside-menu {
		 .menu-nav{
			 padding: 56px 0;
		 }
	}
}