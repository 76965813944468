@import "../../init";
.flex-width {
    flex: 65%;
}
.card-toolbar {
    .logout-btn,
    .save-btn {
        min-width: 75px;
    }
}
.no-of-page {
    text-align: center;
    font-weight: bold;
    margin-bottom: 0;
    color: #000;
}
.approve-msg {
    font-weight: bold;
    font-size: 16px;
}

.clear-text {
    align-self: center;
    span {
        color: blue;
        cursor: pointer;
    }
}

.overflow-scroll {
    overflow: auto !important;
}

.btn.btn-primary {
    background-color: $btn-primary !important;
    color: #fff;
    border-color: $btn-primary !important;
}

.upload-file-name {
    word-break: break-all;
}

.signelectronic-popup {
    select {
        width: 100%;
        word-break: break-all;

        option {
            word-wrap: break-word;
        }
    }

    input {
        width: 100%;
    }
}

.form-content-wrap {
    padding-bottom: 1px;
    position: relative;
    .multiple-text-line {
        border-bottom: 1px solid #cecece;
        p {
            margin: 0;
        }
    }
    .Date_picker_icon-wrap {
        .mobile-view {
            .picker-image {
                z-index: 999 !important;
            }
            input {
                appearance: none;
            }
        }
    }
    .select-box-wrap {
        position: relative;
        flex: 0.5;

        select {
            //  appearance: none;
            width: 100%;
            -webkit-appearance: menu;
        }
        .select-icon {
            position: absolute;
            right: 0;
            top: 10px;
            width: 11px;
        }
    }
    select:required:invalid {
        color: #d8d8d8 !important;
    }

    //   option[value=""][disabled] {
    //     display: none;
    //   }
    option {
        color: black;
    }

    .height-scroll {
        max-height: 150px;
        overflow-x: auto;
    }

    .form_inner {
        padding: 10px 30px;
        border: 2px solid #e2e1e1;
        margin: 4px auto;
        width: 75%;
        border-radius: 10px;
        height: calc(100vh - 191px);
        overflow: auto;

        .spinner-wrap {
            position: absolute;
            top: 50%;
            left: 50%;
        }
    }
    .form_inner_session {
        height: calc(100vh - 228px);
    }

    .sign-data {
        border-bottom: 2px solid #999;
        padding-top: 10px;

        .sign-label {
            font-weight: bold;
        }
    }
}

.save-btn {
    background-color: $btn-primary !important;
    color: #fff;
    border-color: $btn-primary !important;
}

.mobile-view {
    .check_box_wrap {
        label {
            line-height: inherit;
            margin-top: 4px !important;
        }
    }
    .capture-image-wrap {
        .inner_wrap {
            height: calc(100vh - 205px);
            overflow-y: auto;
            overflow-x: hidden;
        }
    }
}
.my-form-wrapper {
    margin-bottom: 30px;
    .capture-image-wrap {
        .Top_header_sign {
            border-radius: 10px 10px 0 0;
        }
        .canvas-capture {
            width: 100%;
            height: 84%;
        }
    }
    input[type="date"],input[type="time"]{
        height: 31px;
    }
    .capture-image-wrap {
        background-color: #fff;
        border-radius: 10px;
        width: 80%;
        margin: 0 auto;
    }
    .bg-color {
        border-radius: 10px 10px 0 0;
    }
    input[type="date"]::-webkit-inner-spin-button,
    input[type="date"]::-webkit-calendar-picker-indicator {
        opacity: 0;
        -webkit-appearance: none;
    }
    .form-lock-footer-wrap {
        //   position: absolute;
        bottom: 0;
        .Info-tooltip {
            display: none;
        }
        .info-icon {
            width: 18px;
            height: 18px;
        }
    }
    .form-lock-footer {
        background: #fc4b42;
        color: #fff;
        display: flex;
        border-radius: 0 0 10px 10px;
        bottom: 0;
        align-items: center;
        z-index: 999;
        padding: 5px 10px;
        p {
            margin: 0;
            font-size: 12px;
        }
        .close-session-btn {
            .tooltip-show:hover .Custom-tooltip {
                display: block !important;
                z-index: 99999;
            }
            button {
                height: 34px;
                background-color: #34434e !important;
                color: #fff;
                font-size: 12px;
                align-items: center;
                display: flex;
            }
        }
    }
    input::placeholder,
    select::placeholder {
        color: #d8d8d8;
    }

    .auto_clear {
        display: flex;
        gap: 10px;
        align-items: center;

        .clear-text {
            color: blue;
            cursor: pointer;
        }

        .clear_btn-dt {
            border: 1px solid #ff5858 !important;
            border-radius: 4px;
            padding: 4px 8px;
            width: 33px;
            cursor: pointer;
            background-color: #ff5858;

            img {
                width: 18px;
                height: 18px;
            }
        }
    }

    .Date_picker_icon-wrap {
        position: relative;
        margin-bottom: 15px;

        input[type="date"] {
            z-index: 93;
            position: absolute;
            -moz-clip-path: inset(0 12px -4px 0);
        }
        input[type="time"]::-webkit-calendar-picker-indicator {
            background: none;
        }
        .picker-image {
            position: absolute;
            right: 0;
            z-index: 0;
            width: 15px;
            top: 4px;
            right: 0px;
            background-color: #fff;
        }
    }

    textarea {
        resize: none;
    }

    .flexwidth {
        .clear_btn {
            width: 33px;
        }
    }

    .phone-flexwidth {
        flex: 0.5;
        max-width: 350px;
    }

    .ongoing-check_inner {
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;

        .label_ {
            margin: 0;
            font-weight: normal;
        }

        .ongoing-checkbox {
            flex: 0;
            margin-top: 0;
        }
    }
    .input_date {
        position: relative;
        .age-text {
            top: 30px;
        }
        .date-field.show-date-placeholder:before {
            color: #d8d8d8;
            content: attr(placeholder);
        }
    }

    .input_date,
    .input_time {
        .label_ {
            display: none;
        }
    }

    .Clear_autofill {
        display: flex;
        gap: 10px;
    }

    .clear-btn-dob {
        width: 33px;
        position: absolute;
        right: -40px;
        bottom: -11px;

        .clear_btn {
            border: 1px solid #ff5858 !important;
            border-radius: 4px;
            margin-top: 10px;
            margin-left: 10px;
            padding: 4px 8px;
            width: 33px;
            cursor: pointer;
            background-color: #ff5858;

            img {
                width: 18px;
                height: 18px;
            }
        }
    }

    .clear_btn {
        border: 1px solid #acacac !important;
        border-radius: 4px;
        
        padding: 4px 8px;
        cursor: pointer;
        background-color: #ebebeb;

        img {
            width: 17px;
            height: 17px;
        }
    }

    .input_lists {
        border: 2px solid #ccc;
        margin-bottom: 10px;

        .inner_input_list {
            padding: 10px 30px;
        }

        .input_list_header {
            background-color: rgb(52, 67, 78);
            font-size: 16px;
            color: #fff;
            padding: 10px;
        }
    }

    .top-form-wrap {
        background-color: #fff;
        border-radius: 10px;

        .topheader {
            background-color: $dark-blue;
            padding: 10px 20px;
            color: #fff;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            .heading {
                margin: 0;
            }
        }
    }

    .left-tab {
        height: calc(100vh - 200px);
        overflow: auto;
        padding: 15px 20px;
        margin-bottom: 10px;
    }

    .tab-content {
        height: calc(100vh - 146px);
        background: white;
        border-radius: 10px;
        margin-bottom: 10px;
    }

    .nav.nav-pills .nav-link.active {
        background: #f3f6f9;
        color: #0070c0;
        box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.08);
        -webkit-box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.08);
        -moz-box-shadow: 2px 2px 5px 3px rgba(0, 0, 0, 0.08);

        p {
            color: #b5b5c3;
        }
    }

    .nav.nav-pills .nav-link {
        display: flex;
        color: #000;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        align-items: flex-start;
        gap: 8px;
        font-weight: bold;
        margin-bottom: 10px;
        border-bottom: 1px solid #dedede;

        &:hover {
            color: #0070c0;
            background-color: #f3f6f9;
        }
    }

    .user-details {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        width: 100%;

        .user-name {
            flex: 40%;
        }

        .time {
            flex: 30%;
        }

        p {
            margin: 0;
            color: #858585;
            font-size: 12px;
            font-weight: normal;
        }

        .status {
            flex: 30%;

            span {
                color: #00d200;
            }
        }
    }

    .no-form {
        text-align: center;
        position: relative;
        top: 200px;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: column;

        .form-icon {
            width: 45px;
        }
    }

    .form-header {
        margin-bottom: 0;
        font-size: 15px;
    }

    .form-content {
        padding: 15px;
    }

    .header-wrap {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: $dark-blue;
        color: #fff;
        padding: 10px;
        border-top-right-radius: 10px;
        border-top-left-radius: 10px;
    }

    .left-tab {
        .no-form {
            flex: 89%;
        }
    }

    .display-none {
        display: none;
    }

    .main_container {
        display: flex;
        margin-bottom: 30px;
        width: 100%;

        hr {
            width: 100%;
        }
       
    }
    .image-view-wrap {
        flex-direction: column !important;
        .label-flex-width{
            width: 35%!important;
        }
        .upload-btn-wrapper{
            width: 100%;
        }
    }
    .input_label {
        flex: 34%;
        display: flex;
        margin-right: 20px;
        justify-content: center;
        align-items: flex-start;
        text-align: right;

        label {
            margin: 0;
        }
    }

    #date_time_wrap {
        flex: 65%;
        display: flex;
        gap: 15px;

        input {
            font-size: 13px !important;
        }
    }

    .helptext-wrap {
        flex: 65%;

        .helptext-inner-wrap{
            input{
                max-width: 350px;
            }
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            .help-text-width{
                width: 40%;
                max-width: 23%;
            }
        }

        .helptext-before{
            flex-direction: row-reverse;
            justify-content: flex-end;
            max-width: 350px;
            input{
                flex: 5!important;
            }
            .help_text{
                margin-right: 7px;
                margin-left: 0;
            }
            .help-text-width{
                width: auto;
                max-width: 40%;
            }
        }

        .help_text {
            margin-top: 7px;
            font-size: 12px;
        }
    }

    .yes_no_toggle {
        gap: 1px !important;
        align-items: center;

        .clear-text {
            margin-left: 15px;
            cursor: pointer;

            span {
                color: blue;
            }
        }

        .clear_btn {
            border: 1px solid #ff5858 !important;
            border-radius: 4px;
            margin-top: 10px;
            margin-left: 10px;
            padding: 4px 8px;
            width: 33px;
            cursor: pointer;
            background-color: #ff5858;

            img {
                width: 18px;
                height: 18px;
            }
        }

        .yes_btn {
            button {
                border-radius: 5px 0 0 5px;
            }
        }

        .na_btn {
            button {
                border-radius: 0 5px 5px 0;
            }
        }

        .no_btn {
            button {
                border-radius: 0;
            }
        }
    }

    .input_wrap {
        display: flex;
        flex-wrap: wrap;
        flex: 65%;
        gap: 15px;
        align-items: flex-start;

        input {
            flex: 1;
            font-size: 13px !important;
        }

        select {
            font-size: 13px !important;
            flex: 0.5;
        }

        .hide-scrollbar {
            overflow-y: hidden;
        }
    }

    .check_box_container {
        align-items: center;
        flex: 65%;
        flex-wrap: wrap;

        .clear_btn {
            border: 1px solid #ff5858 !important;
            border-radius: 4px;
            margin-top: 10px;
            margin-left: 10px;
            padding: 4px 8px;
            width: 33px;
            cursor: pointer;
            background-color: #ff5858;

            img {
                width: 18px;
                height: 18px;
            }
        }

        .check_box_wrap {
            display: flex;
            gap: 10px;
            margin-right: 10px;
            margin-bottom: 8px;
            align-items: flex-start;

            input {
                margin: 0;
                width: 20px;
            }

            label {
                font-weight: normal;
                margin-bottom: 0;
                flex: 4;
                line-height: 1;
            }
        }
    }

    .input_date,
    .input_time,
    .name_input {
        flex: 0.5;

        input {
            width: 100%;
            flex: 1;
        }
    }

    .address_list {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        width: 65%;

        .address_list_input {
            flex: 34%;

            input {
                width: 100%;
                font-size: 13px !important;
            }
        }

        select {
            font-size: 13px !important;
            height: 30px;
        }
    }

    .input_text {
        input {
            width: 100%;
            height: 40px;
        }
    }

    .card-toolbar {
        text-align: left;
        padding-bottom: 15px;
        display: flex;
        gap: 15px;
        display: flex;
        justify-content: space-between;
        margin-top: 15px;
        .change-pass-btn,
        .save-btn {
            width: 100%;
            display: flex;
            background-color: $btn-primary;
            border-color: $btn-primary;

            &:hover,
            &:focus {
                background-color: $btn-primary !important;
                border-color: $btn-primary !important;
            }
        }

        .left-btn,
        .right-btn {
            display: flex;
            gap: 15px;
        }

        .right-btn {
            .spinner-white {
                width: 10px;
                height: 10px;
            }
        }
    }

    .form_title {
        font-size: 15px;
        font-weight: bold;
        margin-bottom: 15px;
        padding: 10px;
        text-align: center;
        border-radius: 4px;
    }

    .form_btn {
        margin-bottom: 10px;
        background-color: $btn-primary;
        padding: 0.5rem 1.5rem;
        border-radius: 6px;
        border: none;
        color: white;
        cursor: pointer;
    }

    textarea {
        border-bottom: 1px solid #cecece;
        width: 100%;
        border-top: none;
        border-left: none;
        border-right: none;
        line-height: 1.6;
    }

    .textype-border {
        border-bottom: none;
    }

    // Upload file
    .upload-btn-wrapper {
        position: relative;
        overflow: hidden;
        justify-content: space-between;
        display: flex;
        gap: 5px !important;
        .img-upload-wrap {
            width: 100%;
            .image_wrap {
                .image-upload {
                    width: 100%;
                    margin: 0;
                }
            }
        }
        
        .image_wrap {
            display: flex;
            margin-top: 12px;
            width: 100%;
            padding: 0 10px;

            p {
                text-overflow: ellipsis;
                overflow: hidden;
                word-break: break-all;
            }

            .open-file {
                color: blue;
                cursor: pointer;

                &:hover {
                    text-decoration: underline;
                }
            }

            img {
                width: 16px;
                margin-left: 10px;
                cursor: pointer;
            }
        }

        .image-wrap_btn {
            display: flex;
            flex-wrap: wrap;
            flex-direction: column;
            width: 100%;
            position: relative;
            padding: 7px 0;
            .spinner-blue {
                position: absolute;
                top: 12px;
                right: 65%;
            }
            .image-loader {
                height: 3px;
            }
            img {
                margin-left: 15px;
            }

            .take-pic {
                width: 150px;
                height: 32px;
            }
        }

        .note_text {
            display: block;
            width: 100%;
            margin-left: 8px;
        }

        .spinner-blue::before {
            border-color: $link-color;
            border-right-color: transparent;
        }
    }

    .upload-btn-wrapper input[type="file"] {
        font-size: 100px;
        position: absolute;
        left: 0;
        top: 0;
        opacity: 0;
        cursor: pointer;
        width: 150px;
    }

    .Questionbtn {
        background-color: #ccc;
        padding: 5px 10px;
        display: inline-block;
        cursor: pointer;
        border-radius: 8px;
        border: none;
        color: white;
    }

    .active_btn_color {
        background-color: $btn-primary;
        padding: 5px 10px;
        display: inline-block;
        cursor: pointer;
        border-radius: 8px;
        border: none;
        color: white;
    }

    .tab-content {
        overflow-x: hidden;
        overflow-y: auto;
    }

    .input_list {
        border: 2px solid #ccc;
        height: 150px;
        margin-bottom: 15px;

        .input_list_header {
            background-color: rgb(52, 67, 78);
            color: #fff;
            padding: 8px;
            font-size: 16px;
        }
    }

    .form-section-head {
        font-size: 16px;
        font-weight: bold;
        border-bottom: 2px solid #dedede;
        margin-bottom: 25px;
        padding-bottom: 10px;
        width: 100%;
        margin-top: 15px;
        background: #e4e4e4;
        padding-top: 10px;
        padding-left: 10px;
        color: black;
        font-weight: 200;
    }

    .input_text {
        margin-bottom: 15px;
    }

    .width {
        width: 100%;
    }

    .name_container {
        gap: 15px;

        .input_wrap {
            flex: 30%;
        }
    }

    .e-sign {
        flex: 38%;
    }

    .open-live-link{
        color: blue;
        cursor: pointer;

        &:hover {
            text-decoration: underline;
            text-underline-offset: 2px;
        }
    }
}

.date_of_birth {
    margin-bottom: 60px !important;

    input[type="text"] {
        display: none;
    }
}

// Camera
.on-video-section {
    height: 100%;
    margin-bottom: 10px;

    video {
        width: 100%;
    }

    .capture_btn {
        margin: 0.5rem;
        background-color: $btn-primary;
        padding: 0.5rem 1.5rem;
        border-radius: 6px;
        border: none;
        color: white;
        cursor: pointer;
    }
}

.signatureCanvas {
    border: 1px solid #cecece;
    border-radius: 4px;
    width: 100%;
    height: 200px;
}

.Top_header_sign {
    background-color: $dark-blue;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h1 {
        color: #fff;
        font-size: 15px;
        margin-bottom: 0;
    }

    .close-icon {
        width: 13px;
        cursor: pointer;
    }
}

.e-sign-popup,
.upload_image_popup {
    z-index: 9999 !important;

    .btn-wrap {
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .save-btn {
        background-color: $btn-primary !important;
        border: none;
        color: white;
        border-radius: 4px;
        padding: 6px 6px;
        width: 80px;
        margin-right: 10px;
    }

    .clear-btn {
        @extend .save-btn;
        background-color: #ff5858;
    }

    .bottom-footer {
        border-top: 1px solid #cecece;
        padding-top: 15px;
    }

    .draw-signature {
        margin: 10px auto;
        border: 1px solid #cecece;
        border-radius: 4px;
        padding: 5px;
    }

    .canvas-capture {
        width: 100%;
        height: calc(100% - 62px);
    }
}

.form-row {
    box-shadow: -1px 1px 8px 0px rgba(0, 0, 0, 0.15);
    -webkit-box-shadow: -1px 1px 8px 0px rgba(0, 0, 0, 0.15);
    -moz-box-shadow: -1px 1px 8px 0px rgba(0, 0, 0, 0.15);
    padding: 15px 20px;
    margin-bottom: 15px;
    border-radius: 6px;
    cursor: pointer;
    flex-wrap: nowrap;

    &:hover {
        border-left: 5px solid $link-color;
        transition: ease-in-out 0.3s;
    }

    h4 {
        font-size: 15px;
        font-weight: 600;
    }

    .form-icon {
        width: 28px;
        margin-right: 15px;
    }
}

.bg-color {
    background-color: #fff;
    border-radius: 10px;
}

.back-btn {
    background-color: transparent;
    border: none;
    cursor: pointer;

    img {
        width: 22px;
    }
}

@media (max-width: 1200px) {
    .main_container {
        .input_wrap {
            select {
                width: 100%;
            }
        }
    }
}
.add-row {
    font-size: 12px;
    padding: 4px 10px;
    margin-bottom: 20px;
}
@media (max-width: 991px) {
    .my-form-wrapper {
        .input_label {
            flex: 60%;
        }

        .main_container {
            .input_label {
                flex: 30%;
            }
        }

        .form_inner {
            border: none;
            width: 100%;
            padding: 10px 20px;
            height: calc(100vh - 200px);
        }
        .form_inner_session {
            height: calc(100vh - 228px);
        }
    }
    .main_container {
        .input_label {
            width: 50%;
        }
        .input_wrap {
            width: 50%;
            select {
                width: 100%;
                word-break: break-all;
                text-overflow: ellipsis;
            }
        }
        .helptext-wrap {
            .text-area-wrap {
                width: 100%;
            }
        }
        .upload-btn-wrapper.e-sign {
            width: 100%;
        }
    }
}

@media (max-width: 768px) {
    .my-form-wrapper {
        width: 100%;
        .upload-btn-wrapper {
            .image-wrap_btn {
                .spinner-blue{
                    right: 30%;
                }
            }
        }
        .select-box-wrap {
            flex: 1;
        }
        .form_inner {
            height: calc(100vh - 273px);
        }
        .form_inner_session {
            height: calc(100vh - 328px);
        }
        .card-toolbar {
            gap: 0px;
            .right-btn {
                gap: 0px;
            }
            .change-detail-btn {
                padding-right: 6px;
            }
        }

        .left-tab {
            height: calc(100vh - 261px);
        }
        .main_container .input_label {
            flex: 50%;
        }

        #date_time_wrap {
            flex-wrap: wrap;
        }

        .yes_no_toggle .clear_btn {
            margin-left: 4px;
            width: 33px;
        }
    }
}

@media (max-width: 610px) {
    .my-form-wrapper {
        .mobile-view {
            .form_inner_session {
                height: calc(100vh - 180px);
            }
            .form_inner {
                height: calc(100vh - 180px);
            }
        }
        .form_inner {
            height: calc(100vh - 318px);
        }
        .form_inner_session {
            height: calc(100vh - 363px);
        }

        .form-lock-footer {
            .desktop-view-text {
                display: none;
            }
            .close-session-btn {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                .Info-tooltip {
                    .Custom-tooltip {
                        left: -285px;
                        /* bottom: 0; */
                        width: 300px;
                        top: -86px;
                        transition: ease-in-out 0.5s;
                    }
                }
                .mobile-view-text {
                    display: block;
                }
                .Info-tooltip {
                    display: block;
                }
            }
        }

        .upload-btn-wrapper {
            .image-wrap_btn {
                .take-pic {
                    width: 136px;
                    height: 28px;
                }
            }
        }
        .ongoing-check_inner {
            margin-top: 10px;
        }

        .auto_clear {
            margin-top: 0;
        }

        .mb-display-none {
            display: none;
        }

        .Date_picker_icon-wrap {
            height: 31px;
        }

        .clear-btn-dob {
            .clear_btn {
                margin-left: 0;
            }
        }

        .input_label {
            justify-content: flex-start;
            text-align: left;
            align-items: flex-start;
        }

        .name_container {
            flex-wrap: wrap;
        }

        .main_container {
            margin-bottom: 20px;
            width: 100%;
        }
        .no-form {
            top: 130px;
        }

        .date_time_wrap {
            display: block;

            .auto_clear {
                margin-top: 10px;
            }
        }

        .input_wrap {
            gap: 8px;

            .name_input {
                margin-bottom: 30px;
            }
        }

        .yes_no_toggle {
            display: flex;
        }

        .top-form-wrap {
            .form-row {
                padding: 12px;

                h4 {
                    font-size: 14px;
                }
            }

            .user-details {
                gap: 10px;
            }

            .form-row {
                .form-icon {
                    width: 22px;
                    margin-right: 10px;
                }
            }

            .left-tab {
                height: calc(100vh - 333px);
            }
        }

        .address_list {
            .address_list_input {
                flex: 70%;
            }
        }
    }

    .Clear_autofill {
        .clear-btn {
            margin: 0;
            gap: 10px;
        }
    }

    .upload_image_popup {
        .canvas-capture {
            height: 100%;
        }

        .bottom-footer {
            padding-top: 35px;
        }
    }
    .main_container {
        .input_label {
            width: 50%;
        }
        .input_wrap {
            width: 100%;
            select {
                width: 100%;
                word-break: break-all;
                text-overflow: ellipsis;
            }
        }
        .helptext-wrap {
            .text-area-wrap {
                width: 100%;
            }
        }
        .upload-btn-wrapper.e-sign {
            width: 100%;
        }
    }
}

@media (max-width: 468px) {
    .upload-btn-wrapper {
        .spinner-blue {
            top: 16px;
        }
        .spinner:before {
            left: 77px;
        }
    }
    .my-form-wrapper{
        .upload-btn-wrapper {
            .image-wrap_btn {
                .spinner-blue{
                    right: 70%;
                }
            }
        }
    }
    
    .form-content-wrap {
        .input_lists {
            .inner_input_list {
                .clear-btn-dob {
                    bottom: 21px;
                }
                .input_date {
                    flex: 0.8;
                }
            }
        }
        .mobile-view {
            height: calc(100vh - 325px);
        }
        .form_inner {
            padding: 10px 15px;
            height: calc(100vh - 330px);
        }
        .form_inner_session {
            height: calc(100vh - 365px);
        }
    }

    .my-form-wrapper {
        margin-bottom: 80px;
        .main_container {
            .input_label {
                flex: 33%;
            }
        }
        .form-lock-footer {
            flex-wrap: wrap;
        }
        .input_lists {
            .inner_input_list {
                padding: 10px 15px;
            }
        }

        .address_list select {
            width: 100%;
        }

        .form-row {
            .form-icon {
                display: none;
            }
        }

        .input_wrap {
            select,
            .input_time,
            .flexwidth,
            .input_date {
                flex: 1;
            }
        }
        .date_of_birth {
            .input_wrap {
                .input_date {
                    flex: 0.8;
                }
                .clear-btn-dob {
                    bottom: 21px;
                }
            }
        }
    }
    .mobile-view {
        margin-bottom: 0;
        .capture-image-wrap {
            width: 100%;
        }
        .form_inner {
            height: calc(100vh - 205px);
        }
        .form_inner_session {
            height: calc(100vh - 250px);
        }
    }
}
@media (max-width: 340px) {
    .my-form-wrapper {
        .card-toolbar {
            flex-wrap: wrap;
            gap: 10px;
        }
    }
}
.mobile-view .Date_picker_icon-wrap {
    .picker-image {
        background-color: #fff !important;
    }

    .android-device {
        z-index: 999 !important;
    }
}
