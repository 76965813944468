
// Desktop Mode
@keyframes fadeIn {
	0% {
	  opacity: 0;
	  transform: translateY(2rem);
	}
	100% {
	  opacity: 1;
	  transform: translateY(0);
	}
  }
  
  .menu {
	animation: fadeIn 0.3s ease-in-out;
  }
.footer{
	.spinner-white{
		position: absolute;
		right: 74px;
	}
	.region-select-drop{
		width: 150px;
		.css-1okebmr-indicatorSeparator{
			display: none;
		}
		.css-1w9j89e-menu{
			-webkit-box-shadow: -1px 6px 19px 0px rgba(0,0,0,0.27);
			-moz-box-shadow: -1px 6px 19px 0px rgba(0,0,0,0.27);
			box-shadow: -1px 6px 19px 0px rgba(0,0,0,0.27);
			transition: ease-in-out 4s;
		}
		svg{
			display: inline-block;
			path{
				fill: #fff;
			}
		}
		img{
			margin-right: 10px;
			width: 30px;
		}
		.css-1uccc91-singleValue{
			color: #fff;
			text-decoration: underline;
			cursor: pointer;
		}
		.css-yk16xz-control, .css-1pahdxg-control, .css-g1d714-ValueContainer{
			background-color: transparent;
			border-color: transparent!important;
			height: 30px;
			&::placeholder{
				border: none;
			}
			&:focus{
				background-color: transparent;
				border: none;
	
			}
			.css-1hb7zxy-IndicatorsContainer{
				display: block;
			}
		}
		.placeholder{
			background-color: transparent;
		}
	}
}

.footer, .aside-minimize.aside-minimize-hover .footer {
	background: $footer-bg-color !important;
padding: 4px 0 !important;
position: fixed;
    bottom: 0;
    width: calc(100% - 265px);
    left: 265px;
}
.aside-minimize .footer {
    left: 70px !important;
    width: 97% !important;
	z-index: 111;
}
.region-wrap{
	display: flex;
	justify-content: space-between;
	align-items: center;
	span{
		flex: 6;
		text-align: left!important;
	}
	.region-select-drop {
		width: 240px;
	}
	.region-select{
		background-color: transparent;
		border: none;
		text-decoration: underline;
		color: #fff;
		appearance: none;
		option{
			color: #000;
			padding: 10px;
		}

	}
	select::-ms-expand {
		display: none;
	}
	.region-select-inner{
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 7px;
		.dropdown-item{
			color: #000!important;
			cursor: pointer;
		}
		.active-region{
			background-color: $link-color;
			color: #fff!important;
		}
		img{
			width: 20px;
			margin-right: 10px;
		}
		.region-btn{
			appearance: none;
			text-decoration: underline;
			color: #fff;
			&::after{
				display: none;
			}
			
		}
	}
}	

@include media-breakpoint-up(lg) {
	.footer {
		// Fixed Mode
		background:$footer-bg-color!important;
    padding: 4px 0 !important;
		.footer-fixed & {
			position: fixed;
			height: get($footer-config, fixed, height);
			z-index: get($footer-config, fixed, zindex);
			box-shadow: get($footer-config, fixed, shadow);
			bottom: 0;
			right: 0;
			left: 0;
		}
		span{
			display: block;
			text-align: center;
			color: #fff !important;
			font-size: 13px !important;
			font-weight: 100 !important;
		}

		// Aside Enabled & Fixed Footer Modes
		.aside-enabled.footer-fixed & {
			left: get($aside-config, base, width);
		}

		// Aside Enabled, Aside Minimized, Aside Minimize Hover & Fixed Footer Modes
		.aside-enabled.aside-minimize.footer-fixed & {
			left: get($aside-config, base, minimized-width);
		}
	}
}
@media (max-width: 997px) {
	.footer{
		position: fixed;
		width: 100%!important;
		text-align: center;
		.region-select-drop{
			width: 200px;
		}
		span{
		color: #fff !important;
		}

	}
}
@media (max-width: 992px){
	.footer{
		left: 0;
	}
}
@include media-breakpoint-down(xs) {
	.footer{
		position: inherit;
		.region-wrap{
			flex-wrap: wrap;
		}
	}

}
