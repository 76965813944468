//
// Base
//

// Body
body {
	background: $page-bg;
}
.header-fixed.subheader-fixed.subheader-enabled .wrapper {
	padding-top: 65px !important;
}
// Desktop Mode
@include media-breakpoint-up(lg) {
	// Wrapper
	.wrapper {
		// Fixed Header
		.header-fixed & {
			padding-top: get($header-config, desktop, fixed, height);
		}

		// Fixed Content Head
		.header-fixed.subheader-fixed.subheader-enabled & {
			padding-top: get($header-config, desktop, fixed, height) + get($subheader-config, fixed, height);
		}
	

		// Fixed Aside
		.aside-fixed & {
			padding-left: get($aside-config, base, width);
		}

		// Minimize Aside
		.aside-fixed.aside-minimize:not(.aside-minimize-hover) & {
			padding-left: get($aside-config, base, minimized-width);
		}
	}

	// Container
	.container,
	.container-fluid {
		padding: 0 get($page-padding, desktop);
	}
}

// Tablet & Mobile Modes
@include media-breakpoint-down(md) {
	// Wrapper
	.wrapper {
		// Fixed Header
		.header-mobile-fixed & {
			padding-top: get($header-config, tablet-and-mobile, fixed, height);
		}

		// Fixed Subheader
		.header-mobile-fixed.subheader-mobile-fixed.subheader-enabled & {
			padding-top: get($header-config, tablet-and-mobile, fixed, height) + get($subheader-config, fixed, height-mobile);
		}
	}

	// Container
	.container,
	.container-fluid {
		max-width: none;
		padding: 0 get($page-padding, tablet-and-mobile);
	}
}
$warning-light: #fff !important;

.bg-light-warning {
    background-color: $warning-light;
    box-shadow: 0 2px 6px rgb(0 0 0 / 7%) !important;
}
//dashboard//
.margin-15{
	margin-top: 15px !important;
}
.margin-8{
	margin-top: 8px !important;
}
.dashboard-card{
	position: relative;
	padding:0 !important;
	height: 210px;
	::-webkit-scrollbar {
		width: 4px;
	  }
	  
	  /* Track */
	  ::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px #DCDCDC; 
		border-radius: 10px;
	  }
	   
	  /* Handle */
	  ::-webkit-scrollbar-thumb {
		background:$dark-blue; 
		border-radius: 10px;
	  }
	  
	  /* Handle on hover */
	  ::-webkit-scrollbar-thumb:hover {
		background: $dark-blue; 
	  }
	.dashborad-yellow-one {
		position: absolute;
		left: 0;
		right: 0;
		background: rgb(231 194 53 / 33%);
		width: 94%;
		height: 100%;
		border-radius: 16px;
		top: -7.5px;
		margin: 0 auto;
	}
	.dashborad-yellow-two {
		position: absolute;
		left: 0;
		right: 0;
		background: rgb(231 194 53 / 33%);
		width: 87%;
		height: 100%;
		border-radius: 16px;
		top: -16.9px;
		margin: 0 auto;
	}
	.dashborad-card-inner {
		position: relative;
		width: 100%;
		float: left;
		background: #fff;
		border-radius: 16px;
		height: 210px;
			h2 {
				font-size: 17px;
				color: #2C2323;
				padding: 12px 15px;
				margin: 0;
			}
			.scrolldiv {
				height: 157px;
				overflow-y: auto;
				.dashboard-odd-line {
					padding: 7.5px 15px;
					background: #F7F7F7;
				}
				.dashboard-even-line{
					background: #ffffff !important;
				}
				h6 {
					font-size: 13px;
					font-weight: 600;
					margin-bottom: 3px;
					color: #494E56;
				}
				p {
					font-size: 13px;
					font-weight: 100;
					margin: 0 !important;
					color: #494E56;
					span{
						font-weight: 600;
					}
				}
			}
	}
}

.header-user .symbol-label {
    border-radius: 50%;
    margin-right: 10px;
}
.dashboard-main-content{
	padding: 0;
}
.container{
	padding: 0 15px !important;
	max-width: 100%;
}
@include media-breakpoint-down(xs) {
.header-user{
	.symbol-label {
		margin-right: 0;
	}
  }
}
@include media-breakpoint-up(xl) {
	.main-wrapper{
		display: grid;
		grid-template-columns: repeat( 3, minmax(30%, 1fr) );
		gap: 0 25px;
	}

 }
 @media (max-width: 1600px){
	.main-wrapper{
		display: grid;
		grid-template-columns: auto auto;
		gap: 20px;
	}
 
 }
 @include media-breakpoint-down(lg) {
	.main-wrapper{
		display: grid;
		grid-template-columns: auto auto;
		gap: 8px 15px;
	}
 }
 @include media-breakpoint-down(sm) {
	.main-wrapper{
		display: grid;
		grid-template-columns: auto ;
		gap: 8px 0;
	}
 }
